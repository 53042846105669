import React, { useState } from "react";
import NavBar from "../components/NavBar/NavBar";
import { Services } from "../services";

export const Dataset = () => {
  const [disabled, setDisabled] = useState(false);

  return (
    <>
      <NavBar />
      <div className="App-container">
        <div className="App-page-title">Generate Dataset</div>
        <div
          id="result"
          className="mb-3"
          style={{ visibility: "hidden", color: "green" }}
        >
          Dataset Generated Successfully.
        </div>
        <button
          className="btn btn-primary"
          disabled={disabled}
          onClick={() => {
            setDisabled(true);
            Services.Exam.generateDataset().then((response) => {
              document.getElementById("result").style.visibility = "visible";
              setTimeout(() => {
                setDisabled(false);
              }, 2000);
            });
          }}
        >
          Generate Dataset
        </button>
      </div>
    </>
  );
};

import { RecordState } from "sg-audio-recorder";
import { Configuration } from "../api/constants";
import { Environments, SECTION_TYPES, SectionLists, URLS } from "../constants/config";
export const BaseURI = URLS.baseuri

export function AudioMarker(state) {
  return state.recordings.filter((x) => { return x.label !== 'full-length' && x.start!=null && x.end!=null }).map((item) => {
    return { label: item.label, start: item.start, end: item.end }
    //return { label: item.label, start: item.start.toFixed(3), end: item.end.toFixed(3) }
  })
}

export function Initialize() {
  let data = {
    // mp3 recorder variables
    isBlocked: false,
    isRecording: false,
    recordingState: RecordState.STOP,

    pagetype: null,
    section: null,
    TimeStampStatus: Configuration.TimeStampStatus.NotProcessed,
    recordState: null,
    hidewelcomescreen: false,
    //switch: show application or show welcome screen
    visibility: false,
    audioData: null,
    showList: false,
    files: [],
    counter: 0,
    letter: "",
    pages: [],
    btnText: "Next",
    btnClass: "btn btn-lg btn-success",
    recordings: [],
  };
  return data
};

export const User = {
  default: {
    username: "student1",
    password: "student1",
    school: "0c08dc1d-884f-42e2-ba87-24ad8c68557a"
  }
}

export const IsLoggedIn = () => {
  let user = localStorage.getItem("user")
  return user && JSON.parse(user)?.userUuid ? true : false
}

export const TestAudio = () => {
  const audios = {
    sec1point5: "test_1point5sec.wav",
    sec1: "test_1point0sec.wav",
    sec3point5: "test_3point5sec.wav",
    shortest: "test.wav"
  }
  return process.env.PUBLIC_URL.concat("/" + audios.sec1)
}
export const ButtonDelay = 0
export const SectionUtility = {
  GetLastAnimation: () => localStorage.get("default_end.lottie.json"),
  GetEgra3Section: (egraS3Json, id) => {
    return egraS3Json.sections.find((x) => { return x.section === id })
  },
  GetSectionDetails: (id) => SectionLists
    .find((x) => { return x.id === parseInt(id) }),
  GetSectionAnimations: (egraS3Json, id) => {
    return egraS3Json.sections.find((x) => { return x.section === id })?.instruction.animation.filter((x) => { return x.type === 'animation' })
  },
  GetInstructionAudioUrl: (egraS3Json, id) => {
    let url = Environments.isDev ? TestAudio() : (egraS3Json?.urlprefix + '/' + egraS3Json.sections.find((x) => { return x.section === id })?.instruction?.audio)
    return url
  },
  GetStoryAudioUrl: (egraS3Json, id) => {
    let url = Environments.isDev ? TestAudio() : (egraS3Json?.urlprefix + '/' + egraS3Json.sections.find((x) => { return x.section === id })?.narration)
    return url
  },
  GetAudioQuestionUrl: (egraS3Json, id, question) => {
    let url = egraS3Json?.urlprefix + '/'
      + egraS3Json.sections
        .find((x) => { return x.section === id }).questions
        .find((x) => { return x === question })
    return url
  },

  GetAudioQuestionUrlAll: (egraS3Json, id) => {
    return egraS3Json.sections
        .find((x) => { return x.section === id }).questions.map(x => {return egraS3Json?.urlprefix + '/'+x})
  },
  AudioPlayer: (audioPath, onendedHandler = null) => {
    const url = process.env.REACT_APP_ENV === Environments.DEVELOPMENT ? TestAudio() : audioPath
    let a = new Audio(url);
    if (onendedHandler) a.onended = onendedHandler;
    a.play();
  },
  DefaultSection: SectionLists.find((x) => {
    return x.default === true;
  }),
  GetNextSection: (currentSection) =>
    SectionLists.find((x) => {
      return x.id === currentSection?.next_section_id;
    }),
  GetTimerParams: (section) => {
    return {
      initalSeconds: section?.timer ? section?.timer?.duration : 0,
      initialFreeze: section?.timer ? false : true,
    };
  },
  IsLastSection: (list, activeSection) => {
    return list.find((x) => {
      return x?.id === activeSection?.next_section_id
    }) ? false : true
  },
  IsSectionListeningType: (section) => {
    return (
      section.page_type &&
      (section.page_type === SECTION_TYPES.LISTENING_QUESTIONS ||
        section.page_type === SECTION_TYPES.LISTENING_NARRATION_AND_QUESTIONS)
    );
  },
  ReaderState: (egraS3Json, selectedSection) => {
    let state = Initialize();
    let section = egraS3Json.sections.find((x) => {
      return x.section === selectedSection?.id;
    });    
    if (!section) return
    state.letter = section.questions[0];
    state.section = section;
    state.pages = Environments.isDev ? section.questions.slice(0, 10)
      : section.questions
    state.pagetype = selectedSection;

    let pages = [];
    for (let item of state.pages) {
      let page_item = {
        label: item,
        value: null,
        blob: null,
        duration: null,
        elapsedTimer: null,
        start: null,
        end: null
      };
      if (SectionUtility.IsSectionListeningType(selectedSection)) {
        page_item = { ...page_item, duration: 0 };
      }
      pages.push(page_item);
    }
    state.recordings = Array.from(pages);
    if (SectionUtility.IsSectionListeningType(selectedSection)) {
      state.recordings.forEach((element) => {
        let audiourl = egraS3Json?.urlprefix + "/" + element.label;
        let au = new Audio(audiourl);
        au.addEventListener(
          "loadedmetadata",
          () => {
            element.listening_duration = au.duration;
          },
          false
        );
      });
    }
    if (!SectionUtility.IsSectionListeningType(selectedSection)) {
      state.visibility = true;
    }
    return state;
  },
  ButtonDelay: (ref, handler = null) => {
    if (ref?.current) {
      ref.current.DisableButton();
      setTimeout(() => {
        if (ref?.current)
          ref.current.EnableButton();
        if (handler)
          handler()
      }, ButtonDelay);
    }
  }
};

import { createAction } from "redux-actions";

export const MCQNextRdxConst = "MCQ/Next";

// create action
export const MCQNextAction = createAction(MCQNextRdxConst);

// Create reducer
export const MCQNextReducer = (state, action) => {
  return {...state,next:"abcdef"};
};



export const MCQNextAnimationRdxConst = "MCQ/NextAnimation";

// create action
export const MCQNextAnimationAction = createAction(MCQNextAnimationRdxConst);

// Create reducer
export const MCQNextAnimationReducer = (state, action) => {
  let n = state.animationcounter+1
  return {...state,animationcounter:n};
};

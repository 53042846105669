import React from "react";
import { useNavigate } from "react-router-dom";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Services } from "../../services";
import styles from "../NavBar/NavBar.module.css"
import { LOGIN_TYPE_URLS } from "../../constants/config";
const NavBar = () => {
  const navigate = useNavigate();
  const LogOut = () => {
    let redirect_url = Services.User.isSquidStudent()?LOGIN_TYPE_URLS.REGULAR:LOGIN_TYPE_URLS.COMPETITION
    navigate(redirect_url)
    localStorage.clear();
    window.location.reload()
  };

  const NewNavBar = ()=>{
    return <>
    <Navbar expand="lg" className="bg-body-tertiary" sticky="top">
      <Container>
        <Navbar.Brand href="/list-recordings">REAL READERS</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/list-recordings">Recordings</Nav.Link>
            {Services.User.isModerator() && <Nav.Link href="/moderate">Moderate</Nav.Link>}
            {Services.User.isTeacher() && <Nav.Link href="/moderate">Roaster</Nav.Link>}
            {Services.User.isModerator() && <Nav.Link href="/dataset">Generate Dataset</Nav.Link>}
            
            {Services.User.isModerator() && <Nav.Link href="/change-password">Change Password</Nav.Link>}
            {Services.User.isTeacher() && <Nav.Link href="/change-password">Change Password/PIN</Nav.Link>}
            <Nav.Link onClick={LogOut}>
              Logout
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <br></br>
  </>
  }
  if(Services.User.isModeratorOrTeacher())
  return <NewNavBar />
  return (
    <div className={styles.NavBar}>
      <div className="p-2">
        <a onClick={LogOut} type="button" className="btn bg-white">
          Logout
        </a>
        &nbsp;
      </div>
    </div>
  );
};

NavBar.propTypes = {};

NavBar.defaultProps = {};

export default NavBar;

import React, { useEffect, useState } from "react";
import { Services } from "../services";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useNavigate, useParams } from "react-router-dom";
export const ResetPasswordForm = () => {
  const navigate = useNavigate()
  const [disabled, setDisabled] = useState(false);
  const params = useParams();
  let formdata = { ...params, password: "" };
  const [message, setMessage] = useState(null);
  useEffect(() => {
    Services.User.CheckPasswordResetToken(params?.reset_token, params?.email)
      .then((response) => {
        if (response?.status !== "success") {
          setMessage(response?.message);
        }
      })
      .catch((e) => {
        console.log(e?.message);
      });
  }, []);

  return (
    <>
      <div className="App-container">
        <div className="App-page-title">Reset Password</div>
        {message && <div className="mb-3"> {message}</div>}
        {!message && (
          <>
            <div
              id="result"
              className="mb-3"
              style={{ visibility: "hidden", color: "green" }}
            >
              Reset link has been sent to your email.
            </div>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                const newpassword =
                  e.currentTarget.elements.item("password").value;
                const payload = { email: params?.email, password: newpassword };
                Services.User.UpdateNewResettedPassword(payload)
                  .then((response) => {
                    localStorage.setItem("user",JSON.stringify(response))
                    navigate("/list-recordings")
                  })
                  .catch((e) => {});
              }}
            >
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalSchool"
              >
                <Form.Label column sm={3}>
                  New Password
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="text"
                    placeholder=""
                    onChange={(e) => {
                      formdata = { ...formdata, password: e.target.value };
                    }}
                    name="password"
                    required={true}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Col sm={{ span: 10, offset: 2 }}>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={disabled}
                  >
                    Change Password
                  </button>
                </Col>
              </Form.Group>
            </Form>{" "}
          </>
        )}
      </div>
    </>
  );
};

import { SectionLists } from "../constants/config";
export const Configuration = {
  TimeoutEnabled:false,
  TimeOutInSeconds: 60,
  TimeStampStatus:{
    Completed:"Completed",
    NotProcessed:"NotProcessed",
    InProgress:"InProgress"
  }
}

export const PageTypes = {
  ListeningComprehension: SectionLists.find((x) => {
    return x.id === 1;
  }),
  LetterSoundIdentification: SectionLists.find((x) => {
    return x.id === 2;
  }),
  NonWordReading: SectionLists.find((x) => {
    return x.id === 3;
  }),
  FamiliarWordReading: SectionLists.find((x) => {
    return x.id === 4;
  }),
  ReadAloud: SectionLists.find((x) => {
    return x.id === 5;
  }),
  Understanding: SectionLists.find((x) => {
    return x.id === 6;
  }),
};

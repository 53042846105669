const TextContainer = (props) => {
    let { label,textSize } = props;
    textSize = textSize?textSize:"30px"
    return (
      <div
        style={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translateX(-50%) translateY(-50%)",
          color: "#FFF",
          fontWeight: "700",
          fontSize: textSize,
          letterSpacing: "3px",
        }}
      >
        {label}
      </div>
    );
  };
  export default TextContainer
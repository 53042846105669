import { useState, useRef, useEffect } from "react";
import Frame from "../components/Frame/Frame";
import NavBar from "../components/NavBar/NavBar";
import TextContainer from "../shared/FrameText";
import { LottieFirstPage } from "./lottie/lottieFirstPage";
import { useNavigate } from "react-router-dom";
import { LOGIN_TYPE_URLS, View } from "../constants/config";
import { IsLoggedIn, SectionUtility } from "../utils/tools";
import { Login } from "./competition-login";
import { SetExamAction } from "../reducers/exam/set-exam-action";
import { useDispatch } from "react-redux";
import { Services } from "../services/index";
import Lottie from "react-lottie-player";
import  ANIM_real_intro1 from  "./intro/lottie/ANIM_real_intro1.json"
import  ANIM_real_intro2 from  "./intro/lottie/ANIM_real_intro2.json"
import  ANIM_real_intro3 from  "./intro/lottie/ANIM_real_intro3.json"
import  ANIM_real_intro4 from  "./intro/lottie/ANIM_real_intro4.json"
import  ANIM_real_intro5 from  "./intro/lottie/ANIM_real_intro5.json"
import  ANIM_real_intro6 from  "./intro/lottie/ANIM_real_intro6.json"
import  ANIM_real_intro7 from  "./intro/lottie/ANIM_real_intro7.json"
import  ANIM_real_intro8 from  "./intro/lottie/ANIM_real_intro8.json"
import  ANIM_real_intro9 from  "./intro/lottie/ANIM_real_intro9.json"
import  ANIM_real_button_prompt1 from  "./intro/lottie/ANIM_real_button-prompt1.json"
import  ANIM_real_button_prompt2 from  "./intro/lottie/ANIM_real_button-prompt2.json"
import  ANIM_real_err1 from  "./intro/lottie/ANIM_real_err1.json"
import  ANIM_real_err2 from  "./intro/lottie/ANIM_real_err2.json"
import  ANIM_real_err3 from  "./intro/lottie/ANIM_real_err3.json"
import  ANIM_real_err4 from  "./intro/lottie/ANIM_real_err4.json"
import  ANIM_real_err5 from  "./intro/lottie/ANIM_real_err5.json"
import  ANIM_real_err6 from  "./intro/lottie/ANIM_real_err6.json"
import { useTimer } from "react-use-precision-timer";
import './intro.css';
import LandingScreen from "../components/Landing/Screen";
import * as LottieAnim from "../constants/LottieAnimations";


export const GlobalIntro = () => {
  const audios = useRef([]);
  const rootIntroUrl = useRef()
  const [view, setView] = useState(View.LandingScreen)
  const [animation,setAnimation] = useState(ANIM_real_intro2)
  const timer = useTimer({ startImmediately: false });
  const noSound = useRef(0)
  const notClickedCounter = useRef(0)
  const audios1DataUrl = useRef()
  const audios2DataUrl = useRef()
  const sayHelloAudio = useRef()
  const audios3DataUrl = useRef()
  const seeYouLater = useRef()
  const clickTheButton = useRef()
  const closingStatAudio = useRef()
  const introData = useRef()
  const dispatch = useDispatch()
  const ref = useRef(null);
  const navigation = useNavigate();
  let audio = useRef()
  const customTimer = useRef()
  let isMicWorking = useRef(false);
  const userClicked = useRef(false)
  const redirectToLogin = ()=>{
    localStorage.clear()
    navigation("/")
    window.location.reload()
  }

  useEffect(()=>{
    if(Services.User.isModerator() || Services.User.isTeacher()){
      navigation("/list-recordings")
    }
  },[])

  const onClick = () => {
    const user = Services.User.getLocalStorageUser()
    const language = user?.language
    Services.Exam.createExam(user).then(async (response) => {
      const examUuid = (await response.json())?.examUuid
      localStorage.setItem("user", JSON.stringify({ ...user, exam: examUuid }));
      Services.Exam.getExam().then(async (response) => {
        const data = await response.json()
        dispatch(SetExamAction({ payload:  data}))
        const selected = SectionUtility.GetEgra3Section(data, 0)
        let audiourl = SectionUtility.GetInstructionAudioUrl(data, selected.section)
        setView(View.LottiePlaying)
        introData.current = await Services.Animation.getSectionAssets(Services.Animation.Categories.Intro).then((data) =>{
          return data
        })



        // rootIntroUrl.current = "/intro/sound/en/"
        // let audioNames = [
        //   rootIntroUrl.current+'EN_real_intro1 .mp3', 
        //   rootIntroUrl.current+'EN_real_intro2.mp3'
        // ]

        // // audioNames.forEach((x) => {
        // await Promise.all(audioNames.map(async (x) => {
        //   const _audio = new Audio(x);
        //   _audio.load();
        //   _audio.addEventListener('canplaythrough', () => {
        //       audios.current.push(_audio)
        //   });
        // }))


        // console.log(audios.current)
        // audios1DataUrl.current = audios.current.find(async (item) => {
        //   return await item?.attributes?.src?.value === rootIntroUrl.current+'EN_real_intro1 .mp3'
        // });

        // audios2DataUrl.current = audios.current.find((item) => {
        //   return item?.attributes?.src?.value === rootIntroUrl.current+'EN_real_intro2.mp3'
        // });

        // console.log(audios1DataUrl.current)

        // const audios1DataUrl = new Audio("/intro/sound/en/EN_real_intro1 .mp3");
        const audios1DataUrl = Services.Animation.getPreloadedAudio(introData.current, "EN_real_intro1 .mp3")
        console.log(introData.current, "introData.current")

        // const audios2DataUrl = new Audio("/intro/sound/en/EN_real_intro2.mp3");
        const audios2DataUrl = Services.Animation.getPreloadedAudio(introData.current, "EN_real_intro2.mp3")

        // const _sayHelloAudio = new Audio("/intro/sound/en/EN_real_err1.mp3");
        // _sayHelloAudio.load();
        // _sayHelloAudio.addEventListener('canplaythrough', () => {
        //   sayHelloAudio.current = _sayHelloAudio
        // });

        sayHelloAudio.current = Services.Animation.getPreloadedAudio(introData.current, "EN_real_err1.mp3")

        // const _audios3DataUrl = new Audio("/intro/sound/en/EN_real_intro3.mp3");
        // _audios3DataUrl.load();
        // _audios3DataUrl.addEventListener('canplaythrough', () => {
        //   audios3DataUrl.current = _audios3DataUrl
        // });
        audios3DataUrl.current = Services.Animation.getPreloadedAudio(introData.current, "EN_real_intro3.mp3")


        // const _seeYouLater = new Audio("/intro/sound/en/EN_real_err2.mp3");
        // _seeYouLater.load();
        // _seeYouLater.addEventListener('canplaythrough', () => {
        //   seeYouLater.current = _seeYouLater
        // });
        seeYouLater.current = Services.Animation.getPreloadedAudio(introData.current, "EN_real_err2.mp3")


        // const _audios3ErrorUrl = new Audio("/intro/sound/en/EN_real_err2.mp3");
        // _audios3ErrorUrl.load();
        // _audios3ErrorUrl.addEventListener('canplaythrough', () => {
        //   audios3ErrorUrl.current = _audios3ErrorUrl
        // });
        clickTheButton.current = Services.Animation.getPreloadedAudio(introData.current, "EN_real_err3.mp3")

        // const _closingStatAudio = new Audio("/intro/sound/en/EN_real_intro4.mp3");        
        // _closingStatAudio.load();
        // _closingStatAudio.addEventListener('canplaythrough', () => {
        //   closingStatAudio.current = _closingStatAudio
        // });
        closingStatAudio.current = Services.Animation.getPreloadedAudio(introData.current, "EN_real_intro4.mp3")

        setTimeout(() => {
          // setAnimation(Services.Animation.getLottieAnimation(introData.current, "ANIM_real_intro3.json"))
          setAnimation(LottieAnim.ANIM_real_intro3)
          audios1DataUrl.play().catch((e) =>{
            console.log(e, "error audio 1 play")
          })
        }, 3000);
    
        audios1DataUrl.addEventListener("ended", () => {
          // setAnimation(Services.Animation.getLottieAnimation(introData.current, "ANIM_real_intro4.json"))
          setAnimation(LottieAnim.ANIM_real_intro4)
          audios2DataUrl.play()
        })
        
        audios2DataUrl.addEventListener("ended", () => {
          // setAnimation(Services.Animation.getLottieAnimation(introData.current, "ANIM_real_intro5.json"))
          setAnimation(LottieAnim.ANIM_real_intro5)
          checkMicAvailability()
        })

        // audio.current.addEventListener('loadedmetadata', (e) => {
        //   console.log(e.target.duration);
        // });

        // audio.play();
        // audio.current.addEventListener('timeupdate', async (e) => {
        //     if (audio.current) {
        //       if(parseInt(audio.current.currentTime) == 19){  
        //         checkMicAvailability()
        //         audio.current.pause();
        //       }
        //     }
        // });

        // setTimeout(() => {
        //   checkMicAvailability()          
        //   audio.current.pause()
        // }, 19000);

      })
    }).catch((error) => {
      console.error(error)
      redirectToLogin()
    })
  }
  const dateDifferenceInSeconds = (dateInitial, dateFinal) => (dateFinal - dateInitial) / 1000;
  const checkMicAvailability = async () => {
    const MIN_DECIBELS = -80;

    const constraints = {
      audio: {
        noiseSuppression: true,
        echoCancellation: true, // Optional: Enable echo cancellation
      },
      video: false,
    };

    // navigator.mediaDevices.getUserMedia({ audio: true })
    navigator.mediaDevices.getUserMedia(constraints)
      .then(stream => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.start();
        // timer.start()
        customTimer.current = new Date()
        const audioContext = new AudioContext();
        const audioStreamSource = audioContext.createMediaStreamSource(stream);
        const analyser = audioContext.createAnalyser();
        analyser.minDecibels = MIN_DECIBELS;
        // analyser.fftSize = 2048;
        audioStreamSource.connect(analyser);
        const bufferLength = analyser.frequencyBinCount;
        const domainData = new Uint8Array(bufferLength);

        const calculateAverage = (dataArray) => {
          let sum = 0;
          for (let i = 0; i < dataArray.length; i++) {
            sum += dataArray[i];
          }
          return sum / dataArray.length;
        };

        let soundDetected = false;
        noSound.current = 0
        notClickedCounter.current = 0
        // let audios2DataUrlNew = audios.current.find((item) => {
        //   return item?.attributes?.src?.value === rootIntroUrl.current+'EN_real_intro2.mp3'
        // });
       
        const detectSound = () => {
          
          if (soundDetected) {
            var timerDiff = dateDifferenceInSeconds(
              customTimer.current,
              new Date()
            )
            audios3DataUrl.current.addEventListener('ended', (e) => {
              customTimer.current = new Date()
            })
            
            if(timerDiff > 3 && timerDiff < 4 && notClickedCounter.current > 4){
              if(!userClicked.current){
                // setAnimation(Services.Animation.getLottieAnimation(introData.current, "ANIM_real_err6.json"))
                setAnimation(LottieAnim.ANIM_real_err6)
                seeYouLater.current.play()
                seeYouLater.current.addEventListener("ended", (e) => {
                  // setAnimation(Services.Animation.getLottieAnimation(introData.current, "ANIM_real_intro9.json"))
                  setAnimation(LottieAnim.ANIM_real_intro9)
                  setTimeout(() => {
                    let redirect_url = Services.User.isSquidStudent()?LOGIN_TYPE_URLS.REGULAR:LOGIN_TYPE_URLS.COMPETITION
                    navigation(redirect_url)
                    localStorage.clear();
                    window.location.reload()
                  }, 1000)
                })
              }
              return
            }

            if(timerDiff > 3 && timerDiff < 4 && notClickedCounter.current >= 2){
              customTimer.current = new Date()
              notClickedCounter.current += 1
              if(notClickedCounter.current >= 2){
                // setAnimation(Services.Animation.getLottieAnimation(introData.current, "ANIM_real_err5.json"))
                setAnimation(LottieAnim.ANIM_real_err5)
              }
              if(!userClicked.current){
                clickTheButton.current.play()
                clickTheButton.current.addEventListener("ended", (e) => {
                  customTimer.current = new Date()
                }) 
              }
            }

            if(timerDiff > 3 && timerDiff < 4 && notClickedCounter.current < 2){
              customTimer.current = new Date()
              if(!userClicked.current){
                if(notClickedCounter.current == 0){
                  // setAnimation(Services.Animation.getLottieAnimation(introData.current, "ANIM_real_err4.json"))
                  setAnimation(LottieAnim.ANIM_real_err4)
                }
                notClickedCounter.current += 1
                clickTheButton.current.play()
                clickTheButton.current.addEventListener("ended", (e) => {
                  customTimer.current = new Date()
                })  
              }
            }
          }

          analyser.getByteFrequencyData(domainData);
          const average = calculateAverage(domainData);
          const threshold = 1;          

          var flag = 0;
          for (let i = 0; i < bufferLength; i++) {
            if(average > threshold){
              soundDetected = true
              isMicWorking.current = true;
              stream.getAudioTracks().forEach((track) => {
                track.stop()
              })
              if(!flag){
                // setAnimation(Services.Animation.getLottieAnimation(introData.current, "ANIM_real_intro6.json"))
                setAnimation(LottieAnim.ANIM_real_intro6)
                sayHelloAudio.current.pause()
                customTimer.current = new Date()
                setTimeout(() => {
                  // setAnimation(Services.Animation.getLottieAnimation(introData.current, "ANIM_real_intro7.json"))
                  setAnimation(LottieAnim.ANIM_real_intro7)
                  setView(View.LottiedEnded)
                  audios3DataUrl.current.play()
                  customTimer.current = new Date()
                }, 1000);
                flag = 1;
              }
            } 

            if(!soundDetected){  
              var timerDiff = dateDifferenceInSeconds(
                customTimer.current,
                new Date()
              )

              if(timerDiff > 10 && timerDiff < 11 && noSound.current>=2){
                console.log("3rd attempt crossed",noSound.current)
                stream.getAudioTracks().forEach((track) => {
                  track.stop()
                })   
                sayHelloAudio.current.currentTime=0
                sayHelloAudio.current.pause()
                customTimer.current = new Date()
                mediaRecorder.stop()
                soundDetected = true
                // setAnimation(Services.Animation.getLottieAnimation(introData.current, "ANIM_real_err3.json"))
                setAnimation(LottieAnim.ANIM_real_err3)
                seeYouLater.current.play()
                seeYouLater.current.addEventListener('ended', (e) => {
                  // setAnimation(Services.Animation.getLottieAnimation(introData.current, "ANIM_real_intro9.json"))
                  setAnimation(LottieAnim.ANIM_real_intro9)
                  setTimeout(() => {
                    let redirect_url = Services.User.isSquidStudent()?LOGIN_TYPE_URLS.REGULAR:LOGIN_TYPE_URLS.COMPETITION
                    navigation(redirect_url)
                    localStorage.clear();
                    window.location.reload()
                  }, 1000)  
                })
              }

              if(timerDiff > 10 && timerDiff < 11 && noSound.current < 2){
                if(noSound.current == 0){
                  // setAnimation(Services.Animation.getLottieAnimation(introData.current, "ANIM_real_err1.json"))
                  setAnimation(LottieAnim.ANIM_real_err1)
                }

                if(noSound.current == 1){
                  // setAnimation(Services.Animation.getLottieAnimation(introData.current, "ANIM_real_err2.json"))
                  setAnimation(LottieAnim.ANIM_real_err2)
                }
                noSound.current += 1
                sayHelloAudio.current.play()
                customTimer.current = new Date()
                sayHelloAudio.current.addEventListener('ended', (e) => {
                    customTimer.current = new Date()
                })
              }
            } 
          }

          window.requestAnimationFrame(detectSound);
        };

        window.requestAnimationFrame(detectSound);
      });
  };

  const IntroAnim = () => {
    return (
      <>
     
      <Lottie
        play={true}
        loop={false}
        animationData={animation}
        path=""
        style={{
          // width: "200px",
          // height: "300px",
          //background: "#86b7fe",
          display: "inline-grid",
          placeContent: "center",
        }}
      />
      </>

    );
  };

  if (!IsLoggedIn()) return (<Login />)
  return (
    <>
      <NavBar />
      
      {view === View.LandingScreen && <LandingScreen onClick={onClick}/>}
      {view === View.LottiedEnded &&
        <Frame ref={ref}
          content={<IntroAnim />}
          handler={() => {
            SectionUtility.ButtonDelay(ref, () => {
              userClicked.current = true
              // const closingStatAudio = new Audio("/intro/sound/en/EN_real_intro4.mp3");
              ref.current.DisableButton()

              // setAnimation(Services.Animation.getLottieAnimation(introData.current, "ANIM_real_intro8.json"))
              setAnimation(LottieAnim.ANIM_real_intro8)
              closingStatAudio.current.play()

              closingStatAudio.current.addEventListener("ended", (e) =>{
                // setAnimation(Services.Animation.getLottieAnimation(introData.current, "ANIM_real_intro9.json"))
                setAnimation(LottieAnim.ANIM_real_intro9)
                setTimeout(()=>{
                  navigation(`/section/${SectionUtility.DefaultSection?.id}`)
                }, 2000)
              })
            })
          }} />}
      {view === View.LottiePlaying && <Frame content={<IntroAnim />} ref={ref} handler={null} />}
    </>
  );
};
import React, { useEffect, useState, useRef } from "react";
import getBlobDuration from "get-blob-duration";
import { IsLoggedIn, SectionUtility } from "../../utils/tools";
import { Configuration } from "../../api/constants";
import UploadSectionData from "../../api/PostToServer";
import { LOGIN_TYPE_URLS, SECTION_TYPES, SectionLists, View, audioSensitivenessControldB } from "../../constants/config";
import { useTimer } from "react-use-precision-timer";
import { Login } from "../../pages/competition-login";
import Frame from "../Frame/Frame";
import TextContainer from "../../shared/FrameText";
import { LottieFirstPage } from "../../pages/lottie/lottieFirstPage";
import HTMLReactParser from "html-react-parser";
import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Services } from "../../services";
import Lottie from "react-lottie-player";
import './anim.css'; 
import * as LottieAnim from "../../constants/LottieAnimations";

import ANIM_real_gem1 from "../../animation-assets/lottie/ANIM_real_gem1-sparkle.json"
import ANIM_real_gem2 from "../../animation-assets/lottie/ANIM_real_gem2-sparkle.json"
import ANIM_real_gem3 from "../../animation-assets/lottie/ANIM_real_gem3-sparkle.json"
import ANIM_real_gem4 from "../../animation-assets/lottie/ANIM_real_gem4-sparkle.json"
import ANIM_real_gem5 from "../../animation-assets/lottie/ANIM_real_gem5-sparkle.json"
import ANIM_real_gem6 from "../../animation-assets/lottie/ANIM_real_gem6-sparkle.json"

const Reader = (props) => {
  const audios = useRef([]);
  const navigation = useNavigate()
  const timer = useTimer({ startImmediately: false });
  const tensecondstimer = useTimer({ startImmediately: false });
  const store = useStore()
  const ref = useRef(null);
  const systemInteract = useRef(false);
  const activeSection = useRef(SectionUtility.GetSectionDetails(props?.section?.id))
  const [state, setState] = useState(SectionUtility.ReaderState(store.getState().exam, activeSection.current));
  const [view, setView] = useState(View.AppView)
  const isRecording = useRef(false)

  const mediaRecorderRef = useRef()
  const sectionData = useRef(props.sectionData)
  const chunksRef = useRef([])
  const streamRef = useRef()
  const isFullRecordTimeout = useRef(false)
  const noSound = useRef(0)
  const breakLoop = useRef(false)
  const speakOnFirstTime = useRef(false)
  const silence10s = useRef(false)
  const isNext = useRef(true)
  const customTimer = useRef(new Date())
  const listeningPose = useRef()
  const recordingPose = useRef()
  const clickTheButton = useRef()
  const seeYouLater = useRef()
  const notClickedCounter = useRef(0)
  const userClicked = useRef()
  const tenSecondEpalsed = useRef(0)
  const sectionAudio = useRef()
  const SFX_real_progress = useRef()
  const SFX_real_gem = useRef()
  const gemAudios = useRef([])
  

  const [animation,setAnimation] = useState()

  const dateDifferenceInSeconds = (dateInitial, dateFinal) => (dateFinal - dateInitial) / 1000;
  function detectSilence(stream, onSoundEnd = _=>{}, onSoundStart = _=>{}, silence_delay = 500, min_decibels = audioSensitivenessControldB.decible) {
    const ctx = new AudioContext();
    const analyser = ctx.createAnalyser();
    const streamNode = ctx.createMediaStreamSource(stream);
    streamNode.connect(analyser);
    analyser.minDecibels = min_decibels;
  
    const data = new Uint8Array(analyser.frequencyBinCount); // will hold our data
    let silence_start = performance.now();
    let triggered = false; // trigger only once per silence event

    const calculateAverage = (dataArray) => {
      let sum = 0;
      for (let i = 0; i < dataArray.length; i++) {
        sum += dataArray[i];
      }
      return sum / dataArray.length;
    };
  
    function loop(time) {
      if(breakLoop.current){
        return


        // audios3DataUrl.current.addEventListener('ended', (e) => {
        //   timer.start()
        // })
        
        // if(timerDiff > 3 && timerDiff < 4 && notClickedCounter.current>=4){
        //   if(!userClicked.current){
        //     setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_err6.json"))
        //     seeYouLater.current.play()
        //     seeYouLater.current.addEventListener("ended", (e) => {
        //       setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_intro9.json"))
        //       setTimeout(() => {
        //         localStorage.clear()
                
        //         navigation('/')
        //         window.location.reload()
        //       }, 1000)
        //     })

        //   }
        //   return
        // }

        // if(timerDiff > 3 && timerDiff < 4 && notClickedCounter.current >= 2){
        //   console.log("user not clicked next 3s elapsed", timerDiff, notClickedCounter.current)
        //   timer.stop()
        //   notClickedCounter.current += 1
        //   if(notClickedCounter.current >= 2){
        //     setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_err5.json"))
        //   }
        //   if(!userClicked.current){
        //     clickTheButton.current.play()
        //     clickTheButton.current.addEventListener("ended", (e) => {
        //       timer.start()
        //     }) 
        //   }
        // }

      }
      requestAnimationFrame(loop); // we'll loop every 60th of a second to check
      analyser.getByteFrequencyData(data); // get current data

      // analyser.getByteFrequencyData(domainData);
      const average = calculateAverage(data);
      const threshold = 1;
      
      // console.log(tenSecondEpalsed.current, "noSound.current")

      if(tenSecondEpalsed.current > 3 && activeSection.current?.id != 5){
        var timerDiff = dateDifferenceInSeconds(
          customTimer.current,
          new Date()
        )
        if(timerDiff > 3 && timerDiff < 4 && notClickedCounter.current>4){
          if(!userClicked.current){
            // const ANIM_real_err6 = require('../../animation-assets/lottie/ANIM_real_err6.json')
            // setAnimation(ANIM_real_err6)
            setAnimation(LottieAnim.ANIM_real_err6)
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_err6.json"))
            seeYouLater.current.play()
            seeYouLater.current.addEventListener("ended", (e) => {
              // const ANIM_real_intro9 = require('../../animation-assets/lottie/ANIM_real_intro9.json')
              // setAnimation(ANIM_real_intro9)
              setAnimation(LottieAnim.ANIM_real_intro9)
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_intro9.json"))
              setTimeout(() => {
                let redirect_url = Services.User.isSquidStudent()?LOGIN_TYPE_URLS.REGULAR:LOGIN_TYPE_URLS.COMPETITION
                navigation(redirect_url)
                localStorage.clear();
                window.location.reload()
              }, 1000)
            })

          }
          breakLoop.current = true
        }
        if(timerDiff > 3 && timerDiff < 4 && notClickedCounter.current <= 4){
          // console.log("user not clicked next 3s elapsed", timerDiff, notClickedCounter.current)
          customTimer.current = new Date()
          if(!userClicked.current){
            if(notClickedCounter.current == 0){
              if(SectionUtility.IsSectionListeningType(activeSection.current)){
                // const ANIM_real_err4 = require('../../animation-assets/lottie/ANIM_real_err4.json')
                // setAnimation(ANIM_real_err4)
                setAnimation(LottieAnim.ANIM_real_err4)
              }
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_err4.json"))
            }
            notClickedCounter.current += 1
            clickTheButton.current.play()
            clickTheButton.current.addEventListener("ended", (e) => {
              customTimer.current = new Date()
            })  
            
          }
        }
      }

      if(tenSecondEpalsed.current > 5 && activeSection.current?.id == 5){
        var timerDiff = dateDifferenceInSeconds(
          customTimer.current,
          new Date()
        )
        if(timerDiff > 3 && timerDiff < 4 && notClickedCounter.current>4){
          if(!userClicked.current){
            // const ANIM_real_err6 = require('../../animation-assets/lottie/ANIM_real_err6.json')
            // setAnimation(ANIM_real_err6)
            setAnimation(LottieAnim.ANIM_real_err6)
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_err6.json"))
            seeYouLater.current.play()
            seeYouLater.current.addEventListener("ended", (e) => {
              // const ANIM_real_intro9 = require('../../animation-assets/lottie/ANIM_real_intro9.json')
              // setAnimation(ANIM_real_intro9)
              setAnimation(LottieAnim.ANIM_real_intro9)
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_intro9.json"))
              setTimeout(() => {
                let redirect_url = Services.User.isSquidStudent()?LOGIN_TYPE_URLS.REGULAR:LOGIN_TYPE_URLS.COMPETITION
                navigation(redirect_url)
                localStorage.clear();
                window.location.reload()
              }, 1000)
            })

          }
          breakLoop.current = true
        }
        if(timerDiff > 3 && timerDiff < 4 && notClickedCounter.current <= 4){
          console.log("user not clicked next 3s elapsed", timerDiff, notClickedCounter.current)
          customTimer.current = new Date()
          if(!userClicked.current){
            if(notClickedCounter.current == 0){
              // const ANIM_real_err4 = require('../../animation-assets/lottie/ANIM_real_err4.json')
              // setAnimation(ANIM_real_err4)
              setAnimation(LottieAnim.ANIM_real_err4)
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_err4.json"))
            }
            notClickedCounter.current += 1
            clickTheButton.current.play()
            clickTheButton.current.addEventListener("ended", (e) => {
              customTimer.current = new Date()
            })  
            
          }
        }
      }

      // if (data.some(v => v)) { // if there is data above the given db limit
      if(average > threshold){
        // console.log(average)
        onSoundStart();
        // if(triggered){
        //   triggered = false;
        //   onSoundStart();
        //   }
        // silence_start = time; // set it to now
      }
      if(time - silence_start > silence_delay) {
        onSoundEnd();
        triggered = true;
      }
    }
    loop();
  }
  
  function onSilence() {
    if(activeSection.current.id == 2 || activeSection.current.id == 3 || activeSection.current.id == 4){
      if(listeningPose.current){
        setAnimation(listeningPose.current)
      }
    }

    var timerDiff = dateDifferenceInSeconds(
      customTimer.current,
      new Date()
    )
    if(activeSection.current?.id != 5){
      if(timerDiff > 10 && timerDiff < 11 && noSound.current>=4){
          console.log("3rd attempt crossed")
          breakLoop.current = true
          // tensecondstimer.stop()
          customTimer.current = new Date()
          isFullRecordTimeout.current = true;
          mediaRecorderRef.current.stop();  
          tenSecondEpalsed.current += 1
          navigation("/no-sound")
      }
    }

    if(timerDiff > 10 && timerDiff < 11){
      customTimer.current = new Date()
      console.log('move next  ', timerDiff)
      // silence10s.current = true
      // tensecondstimer.stop()
      // tensecondstimer.start()
      tenSecondEpalsed.current += 1
      if(isNext.current){
        noSound.current += 1
      }
      // stop({systemInteract : true})
    }

    // if((tensecondstimer.getElapsedRunningTime()/1000) > 10 && (tensecondstimer.getElapsedRunningTime()/1000) < 11 && noSound.current>=2){
    //     console.log("3rd attempt crossed")
    //     breakLoop.current = true
    //     tensecondstimer.stop()
    //     isFullRecordTimeout.current = true;
    //     mediaRecorderRef.current.stop();  
    //     navigation("/no-sound")
    // }

    // if((tensecondstimer.getElapsedRunningTime()/1000) > 10 && (tensecondstimer.getElapsedRunningTime()/1000) < 11){
      
    //   console.log('move next  ',tensecondstimer.getElapsedRunningTime()/1000)
    //   silence10s.current = true
    //   tensecondstimer.stop()
    //   tensecondstimer.start()
    //   if(isNext.current){
    //     noSound.current += 1
    //     console.log(noSound.current)
    //   }
    //   // noSound.current += 1
    //   stop({systemInteract : true})
    // }

  }

  function onSpeak() {
    // if(activeSection.current.id == 2 || activeSection.current.id == 3 || activeSection.current.id == 4){
    //   if(recordingPose.current){
    //     setTimeout(() => {
    //       setAnimation(recordingPose.current)
    //     }, 1000)
    //   }
    // }
    // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5.json"))
    if(!speakOnFirstTime.current && isNext.current){
      console.log("entered recording")
        if(recordingPose.current){
          setAnimation(recordingPose.current)
        }
      
      speakOnFirstTime.current = true
      isNext.current = false
    //   tensecondstimer.stop()
    //   tensecondstimer.start()
    //   noSound.current = 0
    //   console.log("inside one time speaking")
    }

    // if(activeSection.current?.id == 5){
    //   customTimer.current = new Date()
    //   notClickedCounter.current = 0
    //   noSound.current = 0
    //   tenSecondEpalsed.current = 0
    // }

    // if(tensecondstimer.getElapsedRunningTime() > 1000){
    //   tensecondstimer.stop()
    //   tensecondstimer.start()
    // }

    noSound.current = 0
    // console.log('OUTSIDE speaking')
  }

  const [gemCounter,setGemCounter] = useState(Services.Animation.getGemValue(activeSection.current?.id))
  const beginRecording = (e = {section1n6 : false}) => {
    const constraints = {
      audio: {
        noiseSuppression: true,
        echoCancellation: true, // Optional: Enable echo cancellation
      },
      video: false,
    };

    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {

      // setAnimation(listeningPose.current)
      streamRef.current = stream
      mediaRecorderRef.current = new MediaRecorder(streamRef.current);
      mediaRecorderRef.current.start();
      ref.current.EnableButton()
      timer.start()
      // tensecondstimer.start()
      customTimer.current = new Date()

      if(!e?.section1n6){
        noSound.current = 0
      }
      detectSilence(stream, onSilence, onSpeak, 500, audioSensitivenessControldB.decible);  
      mediaRecorderRef.current.ondataavailable = (e) => {
        chunksRef.current.push(e.data)
      }
      mediaRecorderRef.current.onstop = (e)=>{
        if(!isFullRecordTimeout.current){
          stopRecording(next)
        }else {
          const blob = new Blob(chunksRef.current, { type: 'audio/mp3;codecs=mp4a' });
          stopRecording(() => {
            setView(View.PostingInProgress)
            let record = state.recordings[state.counter];
            getBlobDuration(URL.createObjectURL(blob)).then(function (duration) {
              record.start = state.counter === 0?0:state.recordings[state.counter - 1].end

              if (SectionUtility.IsSectionListeningType(activeSection.current)){
                record.end = record.start + duration;  
              }else {
                record.end = duration;  
              }
               
              setState({ ...state })
              breakLoop.current = true

              UploadSectionData(state).then(() => {
                // const SFX_real_progress = Services.Animation.getPreloadedAudio(sectionData.current, "SFX_real_progress.mp3")
                // const SFX_real_gem = Services.Animation.getPreloadedAudio(sectionData.current, "SFX_real_gem.mp3")       
                
                if(!SectionUtility.IsLastSection(SectionLists, activeSection.current)){
                  // console.log(gemAudios.current, "gemAudios")

                  // let SFX_real_progressAudio = gemAudios.current.find((item) => {
                  //   return item?.attributes?.nodeValue === "/animation-assets/en/SFX_real_progress.mp3"
                  // });

                  // console.log(SFX_real_progressAudio, "SFX_real_progressAudio")
                  // SFX_real_progressAudio.play()

                  // let SFX_real_gemAudio = gemAudios.current.find((item) => {
                  //   return item?.attributes?.nodeValue === "/animation-assets/en/SFX_real_progress.mp3"
                  // });

                  // SFX_real_progressAudio.addEventListener("ended", (e)=>{
                  //   SFX_real_gemAudio.play()
                  // })

                  // SFX_real_gemAudio.addEventListener("ended", (e)=>{
                  //   navigation(`/section/${activeSection.current?.next_section_id}`)
                  //   window.location.reload()
                  // })
                  

                  navigation(`/section/${activeSection.current?.next_section_id}`)
                  window.location.reload()
                  // if(SFX_real_progress.current && SFX_real_gem.current){
                  //   SFX_real_progress.current.play()
                  //   SFX_real_progress.current.addEventListener("ended", () => {
                  //     SFX_real_gem.current.play()
                  //   })
                  //   SFX_real_gem.current.addEventListener("ended", () => {
                  //     //setView(View.InProgress)
                  //     navigation(`/section/${activeSection.current?.next_section_id}`)
                  //     window.location.reload()
                  //   })
                  //  }else{
                  //   navigation(`/section/${activeSection.current?.next_section_id}`)
                  //   window.location.reload()
                  //  }
                } else {
                  setView(View.SectionSummary)
                }
              });
            });
          })
        }
      }
      isRecording.current = true
      setState({ ...state });

      // timeout related codes
      let duration = activeSection.current?.timer?.duration
      const isAllowedSections = [2, 3, 4, 5].includes(activeSection.current?.id)
      if (duration && isAllowedSections && Configuration.TimeoutEnabled) {
        //converting to ms
        duration*= 1000
        setTimeout(() => {
          if (View.AppView === view && isRecording.current===true) {
            isFullRecordTimeout.current = true;
            mediaRecorderRef.current.stop();
          }
        }, duration);
      }
    })
      .catch((err) => {
        navigation("/")        
        alert(`Allow microphone access. The following error occurred: ${err}`);
      });
  };

  const start = () => {
    if (state.isBlocked) {
      console.log('Permission Denied');
    } else {
      timer.start()
    }
  };

  const stopTracks = () =>{
    streamRef.current.getAudioTracks().forEach((track) => {      
      track.stop()
    })
    // mediaRecorderRef.current = null;
    chunksRef.current = [];
  }

  const stopRecording = (callBack) => {
    let record = state.recordings[state.counter];
    const blob = new Blob(chunksRef.current, { type: 'audio/mp3;codecs=mp4a' });
    console.log(blob)
    console.log(URL.createObjectURL(blob))
    stopTracks()
    //making new full record
    const fullrecord = {
      label: "full-length",
      value: "full-length",
      blob: null,
      duration: null,
      elapsedTimer: null
    }
    getBlobDuration(URL.createObjectURL(blob)).then(function (duration) {
      //correcting duration
      // if(!SectionUtility.IsSectionListeningType(activeSection.current)){
      //   record.end = duration        
      // }

      fullrecord.duration = duration;
      fullrecord.blob = blob;
      if(!SectionUtility.IsSectionListeningType(activeSection.current)){
                state.recordings.push(fullrecord)
      } else {
        record.blob = blob
        //new computation of listening section
        record.start = state.counter === 0 ? 0 : state.recordings[state.counter - 1].end
        record.end = duration + record.start
      }

      setState({ ...state });
      callBack()
    });
  }

  const stop = (e = {systemInteract : false}) => {
    if(e?.systemInteract===undefined || e?.systemInteract===false){
      noSound.current = 0
      isNext.current = true
      speakOnFirstTime.current = false
      silence10s.current = false
    }

    // console.log(noSound.current, "noSound.current")

    // tensecondstimer.stop()
    // tensecondstimer.start()
    customTimer.current = new Date()
    let record = state.recordings[state.counter];
    let elapsed = timer.getElapsedRunningTime() / 1000
    timer.stop();
    record.elapsedTimer = elapsed;
    record.start = state.counter === 0?0:state.recordings[state.counter - 1].end
    record.end = record.start + elapsed    
    setState({ ...state })

    if (!SectionUtility.IsSectionListeningType(activeSection.current)){
      isFullRecordTimeout.current = true
    }

    if (SectionUtility.IsSectionListeningType(activeSection.current) || state.counter === state.pages.length - 1) {
      mediaRecorderRef.current.stop()      
      isRecording.current = false
      ref.current.DisableButton()
    } else {
      next();
    }
  };


  useEffect(() => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {      
      alert('Your browser does not support recording!');
      return;
    }

      // const SFX_real_progress = useRef()
      // const SFX_real_gem = useRef()
      const SFX_real_progressAud = new Audio("/animation-assets/en/SFX_real_progress.mp3")
      SFX_real_progressAud.load()
      SFX_real_progressAud.addEventListener('canplaythrough', (e) => {
        SFX_real_progress.current = SFX_real_progressAud
      });

      const SFX_real_gemAud = new Audio("/animation-assets/en/SFX_real_gem.mp3")
      SFX_real_gemAud.load()
      SFX_real_gemAud.addEventListener('canplaythrough', (e) => {
        SFX_real_gem.current = SFX_real_gemAud
      });

    // if (SectionUtility.IsSectionListeningType(activeSection.current)) {
    //   if (SECTION_TYPES.LISTENING_NARRATION_AND_QUESTIONS === activeSection.current.page_type) {
    //     let url1 = SectionUtility.GetAudioQuestionUrl(store.getState().exam, activeSection.current.id, state.letter)
    //     const audio = new Audio(url1);
    //     let url2 = SectionUtility.GetStoryAudioUrl(store.getState().exam, activeSection?.current?.id)
    //     const a = new Audio(url2);
    //     ref.current.DisableButton()
    //     a.play()
    //     a.addEventListener("ended", async () => {
    //       audio.play()
    //       audio.addEventListener("ended", () => {
    //         beginRecording()
    //         ref?.current.EnableButton()
    //       })
          
    //     })
    //     setPreloadAudio()
    //   }
    //   if (SECTION_TYPES.LISTENING_QUESTIONS === activeSection.current.page_type) {
    //     let url = SectionUtility.GetAudioQuestionUrl(store.getState().exam, activeSection.current.id, state.letter)
    //     const audio = new Audio(url)
    //     ref.current.DisableButton()   
    //     audio.play()
    //     audio.addEventListener("ended", () => {
    //       beginRecording({section1n6 : true});
    //       ref.current.EnableButton()
    //     })
    //     setPreloadAudio()
    //   }

    // } else { 
    //   console.log("section 3 called")
    //   beginRecording() 
    // }


      var languageSwitch = Services?.User?.getLanguage()
      var prefix = ""
      if(languageSwitch == 'ea'){
        prefix = "ZAM-"
      }
      
      let sectionPage = ""
      switch(parseInt(activeSection.current.id)) {
        case 1:
          ref?.current.DisableButton()
          // sectionPage = Services.Animation.Categories.Listening
          // Services.Animation.getSectionAssets(sectionPage).then((data) =>{
          //   sectionData.current = data
            clickTheButton.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3.mp3")
            seeYouLater.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err2.mp3")
            const EN_real_listen4 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_listen4.mp3")
            const EN_real_listen5 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_listen5.mp3")
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_listen4.json"))
            setAnimation(LottieAnim.ANIM_real_listen4)
            EN_real_listen4.play()
            EN_real_listen4.addEventListener("ended", (e) => {
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_listen5.json"))
              setAnimation(LottieAnim.ANIM_real_listen5)
              console.log("ANIM_real_listen5")
              setTimeout(()=>{
                EN_real_listen5.play()
              }, 500)
            })
            EN_real_listen5.addEventListener("ended", (e) =>{
              setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_listen7.json"))
              setAnimation(LottieAnim.ANIM_real_listen7)
              // listeningPose.current = Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_listen7.json")
              // recordingPose.current = Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_listen8.json")
              recordingPose.current = LottieAnim.ANIM_real_listen8
              beginRecording()
              // ref?.current.EnableButton()
            })

          // })
          break;

        case 2:
          ref?.current.DisableButton()
          // sectionPage = Services.Animation.Categories.Letter
          // Services.Animation.getSectionAssets(sectionPage).then((data) =>{
          //   sectionData.current = data
            clickTheButton.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3.mp3")
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5.json"))
            seeYouLater.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err2.mp3")
            // listeningPose.current = Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter13.json")
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter13.json"))
            setAnimation(LottieAnim.ANIM_real_letter13)
            // recordingPose.current = Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter6.json")
            recordingPose.current = LottieAnim.ANIM_real_letter6
            beginRecording()
            // ref?.current.EnableButton()
          // })
          break;
        case 3:
          // sectionPage = Services.Animation.Categories.Nonword
          // Services.Animation.getSectionAssets(sectionPage).then((data) =>{
          //   sectionData.current = data
            clickTheButton.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3.mp3")
            seeYouLater.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err2.mp3")
            // listeningPose.current = Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5.json")
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5.json"))
            setAnimation(LottieAnim.ANIM_real_letter5)
            // recordingPose.current = Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter6.json")
            recordingPose.current = LottieAnim.ANIM_real_letter6
            beginRecording()
            // ref?.current.EnableButton()
          // })
          break;
        case 4:
          // sectionPage = Services.Animation.Categories.Familiar
          // Services.Animation.getSectionAssets(sectionPage).then((data) =>{
          //   sectionData.current = data
            clickTheButton.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3.mp3")
            seeYouLater.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err2.mp3")
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5.json"))
            setAnimation(LottieAnim.ANIM_real_letter5)
            // listeningPose.current = Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5.json")
            // recordingPose.current = Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter6.json")
            recordingPose.current = LottieAnim.ANIM_real_letter6

            beginRecording()
            // ref?.current.EnableButton()
          // })
          break;
        case 5:
          // sectionPage = Services.Animation.Categories.Comprehension
          // Services.Animation.getSectionAssets(sectionPage).then((data) =>{
          //   sectionData.current = data

            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5.json"))

            clickTheButton.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3.mp3")
            seeYouLater.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err2.mp3")
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5.json"))
            setAnimation(LottieAnim.ANIM_real_letter5)
            // listeningPose.current = Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5.json")
            // recordingPose.current = Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter6.json")
            recordingPose.current = LottieAnim.ANIM_real_letter6
            beginRecording()
            // ref?.current.EnableButton()
          // })
          break;
        case 6:
          ref?.current.DisableButton()
          // sectionPage = Services.Animation.Categories.Comprehension
          // Services.Animation.getSectionAssets(sectionPage).then((data) =>{

          //   sectionData.current = data
            clickTheButton.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3.mp3")
            seeYouLater.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err2.mp3")
            const EN_real_comp2 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_comp2.mp3")
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_comp1.json"))
            setAnimation(LottieAnim.ANIM_real_comp1)
            EN_real_comp2.play()
            EN_real_comp2.addEventListener("ended", () => {
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5.json"))
              setAnimation(LottieAnim.ANIM_real_letter5)
              // listeningPose.current = Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5.json")
              // recordingPose.current = Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter6.json")
              recordingPose.current = LottieAnim.ANIM_real_letter6
              beginRecording()
              // ref?.current.EnableButton()
            })
          // })
          break;
        case 7:

          // ref?.current.DisableButton()
          // sectionPage = Services.Animation.Categories.Phonemic
          // Services.Animation.getSectionAssets(sectionPage).then((data) =>{
          //   sectionData.current = data
            // clickTheButton.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3.mp3")
            // seeYouLater.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err2.mp3")
            // const EN_real_phonemic11 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_phonemic11.mp3")
            // // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic4.json"))
            // setAnimation(LottieAnim.ANIM_real_phonemic4)
            // EN_real_phonemic11.play()
            // EN_real_phonemic11.addEventListener("ended", () => {
            //   // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5.json"))
            //   setAnimation(LottieAnim.ANIM_real_intro5)
            //   // listeningPose.current = Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_intro5.json")
            //   recordingPose.current = LottieAnim.ANIM_real_intro6
            //   beginRecording()
            //   // ref?.current.EnableButton()
            // })

          // }).catch((e) =>{
          //   console.log(e)
          // })

          sectionPage = Services.Animation.Categories.Phonemic
          ref?.current.DisableButton()
          console.log("caled")
          clickTheButton.current = new Audio("/animation-assets/"+languageSwitch+"/"+prefix+"EN_real_err3.mp3")
          seeYouLater.current = new Audio("/animation-assets/"+languageSwitch+"/"+prefix+"EN_real_err2.mp3")
          const EN_real_phonemic11 = new Audio("/animation-assets/"+languageSwitch+"/"+prefix+"EN_real_phonemic11.mp3")
          // const ANIM_real_phonemic4 = require('../../animation-assets/lottie/ANIM_real_phonemic4.json')
          // const ANIM_real_intro5 = require('../../animation-assets/lottie/ANIM_real_intro5.json')
          const ANIM_real_intro6 = require('../../animation-assets/lottie/ANIM_real_intro6.json')
          // setAnimation(ANIM_real_phonemic4)
          setAnimation(LottieAnim.ANIM_real_phonemic4)
          EN_real_phonemic11.play()
          EN_real_phonemic11.addEventListener("ended", () => {
            // setAnimation(ANIM_real_intro5)
            setAnimation(LottieAnim.ANIM_real_intro5)
            // listeningPose.current = Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_intro5.json")
            recordingPose.current = ANIM_real_intro6
            beginRecording()
            // ref?.current.EnableButton()
          })


          break;
      }

      if (SectionUtility.IsSectionListeningType(activeSection.current)) {
        setPreloadAudio()
      }

  }, [])



  const setPreloadAudio = () =>{
    let urls = SectionUtility.GetAudioQuestionUrlAll(store.getState().exam, activeSection.current.id)
    urls.forEach((x) => {
      const _audio = new Audio(x);
      _audio.load();
      _audio.addEventListener('canplaythrough', () => {
          audios.current.push(_audio)
      });
    })
  }

  const next = async () => {
    let newCount = state.counter + 1;
    notClickedCounter.current = 0
    noSound.current = 0
    tenSecondEpalsed.current = 0
    customTimer.current = new Date()

    if (state.counter === state.pages.length - 1) { 
      
      //setGemCounter(Services.Animation.getGemValue(activeSection.current.id,"ended"))
      // let sectionPage = ""
      // switch(parseInt(activeSection.current.id)) {
      //   case 1:
      //     ref?.current.DisableButton()
      //     sectionPage = Services.Animation.Categories.Listening
      //     Services.Animation.getSectionAssets(sectionPage).then((data) =>{
      //       sectionData.current = data
            
      //     })
      //     break;

      //   case 2:
      //     ref?.current.DisableButton()
      //     sectionPage = Services.Animation.Categories.Letter
      //     Services.Animation.getSectionAssets(sectionPage).then((data) =>{
      //       sectionData.current = data
            
      //     })
      //     break;
      //   case 3:
      //     sectionPage = Services.Animation.Categories.Nonword
      //     Services.Animation.getSectionAssets(sectionPage).then((data) =>{
      //       sectionData.current = data
      //     })
      //     break;
      //   case 4:
      //     sectionPage = Services.Animation.Categories.Familiar
      //     Services.Animation.getSectionAssets(sectionPage).then((data) =>{
      //       sectionData.current = data
            
      //     })
      //     break;
      //   case 5:
      //     sectionPage = Services.Animation.Categories.Comprehension
      //     Services.Animation.getSectionAssets(sectionPage).then((data) =>{
      //       sectionData.current = data
      //     })
      //     break;
      //   case 6:
      //     ref?.current.DisableButton()
      //     sectionPage = Services.Animation.Categories.Comprehension
      //     Services.Animation.getSectionAssets(sectionPage).then((data) =>{

      //       breakLoop.current = true     
      //       state.TimeStampStatus = Configuration.TimeStampStatus.InProgress;
      //       setView(View.PostingInProgress)
      //       setState({ ...state });
      //       const promise = UploadSectionData(state);
      //       promise.then(() => {
      //         state.TimeStampStatus = Configuration.TimeStampStatus.Completed;
      //         setView(View.SectionSummary)
      //         setState({ ...state });
      //       });

      //       sectionData.current = data
      //       const SFX_real_progress = Services.Animation.getPreloadedAudio(sectionData.current, "SFX_real_progress.mp3")
      //       const SFX_real_gem = Services.Animation.getPreloadedAudio(sectionData.current, "SFX_real_gem.mp3")
      //       const EN_real_comp7 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_comp7.mp3")
      //       setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_comp3.json"))
      //       SFX_real_progress.play()
      //       SFX_real_progress.addEventListener("ended", () => {
      //         setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_gem6.json"))
      //         SFX_real_gem.play()
      //       })
      //       SFX_real_gem.addEventListener("ended", () => {
      //         setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter16.json"))
      //         EN_real_comp7.play()
      //       })
      //       EN_real_comp7.addEventListener("ended", () => {
      //         setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter17.json"))              
      //         console.log(gemCounter)
      //       })
            
      //     })
      //     break;
      //   case 7:
      //     ref?.current.DisableButton()
      //     sectionPage = Services.Animation.Categories.Phonemic
      //     Services.Animation.getSectionAssets(sectionPage).then((data) =>{
      //       sectionData.current = data
            
      //     }).catch((e) =>{
      //       console.log(e)
      //     })
      //     break;
      // }

      breakLoop.current = true     
      state.TimeStampStatus = Configuration.TimeStampStatus.InProgress;
      setView(View.PostingInProgress)
      setState({ ...state });
      UploadSectionData(state).then(() => {
        state.TimeStampStatus = Configuration.TimeStampStatus.Completed;
        // setView(View.SectionSummary)
        // if(SectionUtility.IsLastSection(SectionLists, activeSection.current)){
        //   const EN_real_comp7 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_comp7.mp3")
        //   EN_real_comp7.play()
        // } else {
        //     console.log("else condition")
        //     const SFX_real_progress = Services.Animation.getPreloadedAudio(sectionData.current, "SFX_real_progress.mp3")
        //     const SFX_real_gem = Services.Animation.getPreloadedAudio(sectionData.current, "SFX_real_gem.mp3")
        //     SFX_real_progress.play()
        //     SFX_real_progress.addEventListener("ended", () => {
        //       SFX_real_gem.play()
        //     })
        // }

        // SFX_real_progress.current = Services.Animation.getPreloadedAudio(sectionData.current, "SFX_real_progress.mp3")
        // SFX_real_gem = Services.Animation.getPreloadedAudio(sectionData.current, "SFX_real_gem.mp3")

        // const SFX_real_progress = Services.Animation.getPreloadedAudio(sectionAudio.current, "SFX_real_progress.mp3")
        // const SFX_real_gem = Services.Animation.getPreloadedAudio(sectionAudio.current, "SFX_real_gem.mp3")

        if(!SectionUtility.IsLastSection(SectionLists, activeSection.current)){
          // navigation(`/section/${activeSection.current?.next_section_id}`)
          // window.location.reload()

          // console.log(gemAudios.current, "gemAudios")

          // let SFX_real_progressAudio = gemAudios.current.find((item) => {
          //   return item?.attributes?.value === "/animation-assets/en/SFX_real_progress.mp3"
          // });

          // SFX_real_progressAudio.play()

          // let SFX_real_gemAudio = gemAudios.current.find((item) => {
          //   return item?.attributes?.value === "/animation-assets/en/SFX_real_progress.mp3"
          // });

          // SFX_real_progressAudio.addEventListener("ended", (e)=>{
          //   SFX_real_gemAudio.play()
          // })

          // SFX_real_gemAudio.addEventListener("ended", (e)=>{
          //   navigation(`/section/${activeSection.current?.next_section_id}`)
          //   window.location.reload()
          // })
          navigation(`/section/${activeSection.current?.next_section_id}`)
          window.location.reload()

        //  if(SFX_real_progress.current && SFX_real_gem.current){
        //   SFX_real_progress.current.play()
        //   SFX_real_progress.current.addEventListener("ended", () => {
        //     SFX_real_gem.current.play()
        //   })
        //   SFX_real_gem.current.addEventListener("ended", () => {
        //     //setView(View.InProgress)
        //     navigation(`/section/${activeSection.current?.next_section_id}`)
        //     window.location.reload()
        //   })
        //  }else{
        //   navigation(`/section/${activeSection.current?.next_section_id}`)
        //   window.location.reload()
        //  }
          
        } else {
          setView(View.SectionSummary)
        }
        setState({ ...state });
      });

    }

    if (state.counter < state.pages.length - 1) {
      // section 2,3,4 and 5
      if (!SectionUtility.IsSectionListeningType(activeSection.current)) {
        console.log("section 7 called first")
        isNext.current = true
        speakOnFirstTime.current = false
        start();
        // ref.current.DisableButton()
        // setTimeout(() => {              
        //   ref.current.EnableButton()
        // }, 200);
      }
      let newLetter = state.pages[newCount];
      state.counter = newCount;
      state.letter = newLetter;
      setState({ ...state });

      // section 1, 6 and 7
      if (SectionUtility.IsSectionListeningType(activeSection.current) && state.letter) {
        console.log("section 7 called second")
        // console.log(tensecondstimer.getElapsedRunningTime()/1000, "timer check")
        isNext.current = true
        speakOnFirstTime.current = false

        // console.log(newLetter)
        // let url = SectionUtility.GetAudioQuestionUrl(store.getState().exam, activeSection.current.id, newLetter)
        // // let audio = new Audio()
        // // audio.src = url

        // let audiosDataUrl = audios.current.find((item) => {
        //   return item.currentSrc === url
        // });

        // let audio = audiosDataUrl
        // audio.play().catch((e) => {
        //   console.log(e, "audio play error")
        // })
        // ref.current.DisableButton()
        // audio.addEventListener("ended", () => {
        //   console.log("next question play ended")
        //   beginRecording({section1n6 : true})
        //   if (ref?.current) {
        //     ref.current.EnableButton()
        //   }
        // })


          // Short method using If 
        // let audioName = newLetter.split('/')[1]
        // console.log(audioName)
        // let sectionPage = ""
        // if(activeSection.current.id == 1){
        //   sectionPage = Services.Animation.Categories.Listening
        // }

        // let lottieName = "ANIM_real_listen9.json"
        // Services.Animation.getSectionAssets(sectionPage).then((data) =>{
        //   sectionData.current = data
        //   const EN_real_listen = Services.Animation.getPreloadedAudio(sectionData.current, audioName)
        //   setAnimation(Services.Animation.getLottieAnimation(sectionData.current, lottieName))
        //   EN_real_listen.play().catch((e)=>{
        //     console.log("question is not played", e)
        //   })
        //   EN_real_listen.addEventListener("ended", () => {
        //     beginRecording({section1n6 : true})
        //     if (ref?.current) {
        //       ref.current.EnableButton()
        //     }
        //   })
        // })

        let audioName = ""
        var languageSwitch = Services?.User?.getLanguage()
        if(languageSwitch == 'ea'){
          audioName = newLetter.split('/ZAM-')[1]
        }

        if(languageSwitch == 'en'){
          audioName = newLetter.split('/')[1]
        }
        let sectionPage = ""
        switch(parseInt(activeSection.current.id)) {
          case 1:
            ref.current.DisableButton()
            // let lottieName = "ANIM_real_listen9.json"
            // sectionPage = Services.Animation.Categories.Listening
            // Services.Animation.getSectionAssets(sectionPage).then((data) =>{
            //   sectionData.current = data
              const EN_real_listen = Services.Animation.getPreloadedAudio(sectionData.current, audioName)
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, lottieName))
              setAnimation(LottieAnim.ANIM_real_listen9)

              EN_real_listen.play().catch((e)=>{
                console.log("question is not played", e)
              })
              EN_real_listen.addEventListener("ended", () => {
                // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_listen7.json"))
                setAnimation(LottieAnim.ANIM_real_listen7)
                // recordingPose.current = Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_listen8.json")
                recordingPose.current = LottieAnim.ANIM_real_listen8
                beginRecording({section1n6 : true})
                // if (ref?.current) {
                //   ref.current.EnableButton()
                // }
              })
            // })
            break;
          case 6:
            // ref.current.DisableButton()
            // let lottieName6 = "ANIM_real_comp2.json"
            // sectionPage = Services.Animation.Categories.Comprehension
            // Services.Animation.getSectionAssets(sectionPage).then((data) =>{
            //   sectionData.current = data
            //   const EN_real_listen = Services.Animation.getPreloadedAudio(sectionData.current, audioName)
            //   EN_real_listen.play()
            //   setAnimation(Services.Animation.getLottieAnimation(sectionData.current, lottieName6))
            //   EN_real_listen.addEventListener("ended", () => {
            //     setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5.json"))
            //     recordingPose.current = Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter6.json")
            //     beginRecording({section1n6 : true})
            //     // if (ref?.current) {
            //     //   ref.current.EnableButton()
            //     // }
            //   })
            // })

            ref.current.DisableButton()
            sectionPage = Services.Animation.Categories.Comprehension
            // let lottieName6 = require('../../animation-assets/lottie/ANIM_real_comp2.json')
            // let ANIM_real_intro5_5 = require('../../animation-assets/lottie/ANIM_real_intro5.json')
            // let ANIM_real_intro6_6 = require('../../animation-assets/lottie/ANIM_real_intro6.json')
            // setAnimation(lottieName6)
            setAnimation(LottieAnim.ANIM_real_comp2)

            let url6 = SectionUtility.GetAudioQuestionUrl(store.getState().exam, activeSection.current.id, newLetter)
            console.log(url6)
            // let audio = new Audio()
            // audio.src = url

            // console.log(audios.current, 'audios.current')

            let audiosDataUrl6 = audios.current.find((item) => {
              return item.currentSrc === url6
            });

            let audio6 = audiosDataUrl6
            audio6.play().catch((e) => {
              console.log(e, "audio play error")
            })
            
            audio6.addEventListener("ended", () => {
              console.log("next question play ended")
              // setAnimation(ANIM_real_intro5_5)
              setAnimation(LottieAnim.ANIM_real_intro5)
              // recordingPose.current = ANIM_real_intro6_6
              recordingPose.current = LottieAnim.ANIM_real_intro6
              beginRecording({section1n6 : true})
              // if (ref?.current) {
              //   ref.current.EnableButton()
              // }
            })

            break;
          case 7:
            // let lottieName9 = "ANIM_real_phonemic9.json"
            // sectionPage = Services.Animation.Categories.Phonemic
            // Services.Animation.getSectionAssets(sectionPage).then((data) =>{
            //   sectionData.current = data
              // const EN_real_listen7 = Services.Animation.getPreloadedAudio(sectionData.current, audioName)
              // EN_real_listen7.play()
              // // console.log("called outside", Services.Animation.getLottieAnimation(sectionData.current, lottieName9))

              // // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, lottieName9))
              // setAnimation(LottieAnim.ANIM_real_phonemic9)
              // EN_real_listen7.addEventListener("ended", (e) => {
              //   console.log("called inside")
              //   // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_intro5.json"))\
              //   setAnimation(LottieAnim.ANIM_real_intro5)
              //   // recordingPose.current = Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_intro6.json")
              //   recordingPose.current = LottieAnim.ANIM_real_intro6
              //   beginRecording({section1n6 : true})
              //   // if (ref?.current) {
              //   //   ref.current.EnableButton()
              //   // }
              // })
            // })

            ref.current.DisableButton()
            // let lottieName9 = require('../../animation-assets/lottie/ANIM_real_phonemic9.json')
            // let ANIM_real_intro5 = require('../../animation-assets/lottie/ANIM_real_intro5.json')
            // let ANIM_real_intro6 = require('../../animation-assets/lottie/ANIM_real_intro6.json')
            // setAnimation(lottieName9)
            setAnimation(LottieAnim.ANIM_real_phonemic9)
            let url = SectionUtility.GetAudioQuestionUrl(store.getState().exam, activeSection.current.id, newLetter)
            let audiosDataUrl = audios.current.find((item) => {
              return item.currentSrc === url
            });

            let audio = audiosDataUrl
            audio.play().catch((e) => {
              console.log(e, "audio play error")
            })
            audio.addEventListener("ended", () => {
              console.log("next question play ended")
              // setAnimation(ANIM_real_intro5)
              setAnimation(LottieAnim.ANIM_real_intro5)
              // recordingPose.current = ANIM_real_intro6
              recordingPose.current = LottieAnim.ANIM_real_intro6
              beginRecording({section1n6 : true})
              // if (ref?.current) {
              //   ref.current.EnableButton()
              // }
            })

            break;
        }
      }
    }
  };

  const IntroAnim = () => {
    return (
      <>
     
      <Lottie
        play={true}
        loop={false}
        animationData={animation}
        path=""
        style={{
          // width: "200px",
          // height: "300px",
          //background: "#86b7fe",
          display: "inline-grid",
          placeContent: "center",
        }}
      />

      </>

    );
  };

  return (
    <>
      {/* {isRecording.current === true && <span style={{fontSize:"3em",color:"white"}}>Recording</span>} */}
      {/* {mediaRecorderRef.current?.state === "recording" && <span style={{fontSize:"3em",color:"white",textDecoration:"blink"}}>Start Speaking</span>} */}
      {!IsLoggedIn() && <Login />}

      {!state.pages && ""}
      {view === View.AppView &&
        SectionUtility.IsSectionListeningType(activeSection.current) && (
          <Frame
          GemValue={Services.Animation.getGemValue(activeSection.current?.id)}
            content={<IntroAnim />}
            ref={ref}
            handler={stop}
          />
        )}
      {/* {(view === View.AppView) && SectionUtility.IsSectionListeningType(activeSection.current) &&
        <Frame GemValue={activeSection.current?.id}
          content={<LottieFirstPage />}
          ref={ref}
          handler={stop}
        />
      } */}

      {view === View.AppView &&
        activeSection.current?.id !== 2 &&
        activeSection.current?.id !== 5 &&
        !SectionUtility.IsSectionListeningType(activeSection.current) && (
          <Frame
          GemValue={Services.Animation.getGemValue(activeSection.current?.id)}
            content={
              // <TextContainer
              // label={HTMLReactParser(state.letter)}
              // textSize={activeSection.current?.id === 5 ? "20px" : null} />

              <div className="container">
                <div className="item">
                  <div
                    style={{
                      position: "relative",
                      // top: "50%",
                      left: "50%",
                      transform: "translateX(-50%) translateY(190%)",
                      // color: "#000",
                      // width:"100px",
                      // height:"100px",
                      // borderRadius:"50px",
                      fontWeight: "900",
                      lineHeight: "95px",
                    }}
                    // class="circle"
                  >
                    <TextContainer
                      label={HTMLReactParser(state.letter)}
                      textSize={activeSection.current?.id === 5 ? "20px" : null}
                    />
                  </div>
                  <div style={{ marginTop: "-80px" }}>
                    <IntroAnim />
                  </div>
                </div>
                {/* <div className="item">{state.letter}</div> */}
                {/* <div className="item"></div> */}
                //{" "}
              </div>
            }
            ref={ref}
            handler={stop}
          />
        )}
      {view === View.AppView &&
        activeSection.current?.id == 5 &&
        !SectionUtility.IsSectionListeningType(activeSection.current) && (
          <Frame
          GemValue={Services.Animation.getGemValue(activeSection.current?.id)}
            content={
              <TextContainer
                label={HTMLReactParser(state.letter)}
                textSize={activeSection.current?.id === 5 ? "20px" : null}
              />
            }
            ref={ref}
            handler={stop}
          />
        )}
      {view === View.AppView &&
        activeSection.current?.id === 2 &&
        !SectionUtility.IsSectionListeningType(activeSection.current) && (
          <Frame
          GemValue={Services.Animation.getGemValue(activeSection.current?.id)}
            content={
              <>
                <div className="container">
                  <div className="item">
                    <div
                      style={{
                        position: "relative",
                        // top: "50%",
                        left: "50%",
                        transform: "translateX(-50%) translateY(120%)",
                        color: "#000",
                        width: "100px",
                        height: "100px",
                        borderRadius: "50px",
                        fontWeight: "900",
                        lineHeight: "95px",
                      }}
                      class="circle"
                    >
                      {state.letter}
                    </div>
                    <div style={{ marginTop: "-80px" }}>
                      <IntroAnim />
                    </div>
                  </div>
                  {/* <div className="item"></div>
              <div className="item"></div> */}
                </div>
                {/* <div 
                style={{
                  position: "relative",
                  top: "50%",
                  left: "50%",
                  transform: "translateX(-50%) translateY(-50%)",
                  color: "#000",
                  width:"100px",
                  height:"100px",
                  borderRadius:"50px",
                  fontWeight:"900",
                  lineHeight:"95px",
                }}
                class="circle">
                {state.letter}
              </div> */}
              </>
            }
            ref={ref}
            handler={stop}
          />
        )}

      {view === View.PostingInProgress && (
        <Frame
        GemValue={Services.Animation.getGemValue(activeSection.current?.id)}
          ref={ref}
          content={
            <Lottie
              play={true}
              loop={true}
              animationData={
                activeSection.current.id === 1
                  ? ANIM_real_gem1
                  : activeSection.current.id === 2
                  ? ANIM_real_gem3
                  : activeSection.current.id === 3
                  ? ANIM_real_gem4
                  : activeSection.current.id === 4
                  ? ANIM_real_gem5
                  : activeSection.current.id === 5
                  ? ANIM_real_gem6
                  : activeSection.current.id === 6
                  ? ANIM_real_gem6
                  : activeSection.current.id === 7
                  ? ANIM_real_gem2
                  : null
              }
              path=""
              style={{
                // width: "200px",
                // height: "300px",
                //background: "#86b7fe",
                display: "inline-grid",
                placeContent: "center",
              }}
            />
          }
          // content={<TextContainer label={HTMLReactParser("&nbsp;Please wait....")} />}
          handler={null}
        />
      )}

      {view === View.SectionSummary && (
        <Frame
          GemValue={Services.Animation.getGemValue(
            activeSection.current?.id,
            "ended"
          )}
          ref={ref}
          content={
            <TextContainer
              label={HTMLReactParser(
                !SectionUtility.IsLastSection(
                  SectionLists,
                  activeSection.current
                )
                  ? "&nbsp;You earned your next gem"
                  : "&nbsp;Hooray! You’re all done! You're a REAL Reader!!!"
              )}
            />
          }
          handler={
            SectionUtility.IsLastSection(SectionLists, activeSection.current)
              ? null
              : () => {
                  setView(View.InProgress);
                  navigation(
                    `/section/${activeSection.current?.next_section_id}`
                  );
                  window.location.reload();
                }
          }
        />
      )}
    </>
  );
};

Reader.propTypes = {};

Reader.defaultProps = {};

export default Reader;

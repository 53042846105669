import { SetExamRdxConst, SetExamReducer } from "./set-exam-action";
import { GetExamRdxConst, GetExamReducer } from "./get-exam-action";
export const examReducer = (state = {}, action) => {
  switch (action.type) {
    case SetExamRdxConst:
      return SetExamReducer(state, action);
    case GetExamRdxConst:
      return GetExamReducer(state, action);
    default:
      return state;
  }
};

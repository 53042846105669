import { combineReducers } from "redux";
import { counterReducer } from "./counter";
import { mcqReducer } from "./mcq";
import { examReducer } from "./exam";

export const rootReducer = combineReducers({
  counter: counterReducer,
  mcq:mcqReducer,
  exam:examReducer
  // other feature reducers come in here
});

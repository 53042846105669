import { useState, useEffect } from "react";

const useFetch = (url) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [url]);

  return [data];
};




export const usePost = (url, token = null,body=null) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token
      },
      method:"POST",
      body:JSON.stringify(body?body:{})
      
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          setData(data);
        });
      }
    });
  }, [url, token]);
  return [data];
};
export default useFetch;

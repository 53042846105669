import { createAction } from "redux-actions";

// create constant
export const IncrementCounterRdxConst = "Counter/IncrementCounter";

// create action
export const IncrementCounterAction = createAction(
  IncrementCounterRdxConst,
  (payload) => payload
);

// Create reducer
export const IncrementCounterReducer = (state, action) => {
  return state + 1;
};

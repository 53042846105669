import { createAction } from "redux-actions";

export const SetSectionInfoRdxConst = "ReadersSectionInfo/Set";

// create action
export const SetSectionInfoAction = createAction(SetSectionInfoRdxConst);

// Create reducer
export const SetSectionInfoReducer = (state, action) => {
    console.log("reducer log")
    console.log(state)
    console.log(action)
  return {...state};
};

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {useStore} from 'react-redux'
import { LOGIN_TYPE_URLS, URLS, View } from "../constants/config";
import NavBar from "../components/NavBar/NavBar";
import Frame from "../components/Frame/Frame";
import TextContainer from "../shared/FrameText";
import { LottieFirstPage } from "./lottie/lottieFirstPage";
import { LottieAnimationSequence } from "../components/lottie-animation-sequence";
import Reader from "../components/Reader/Reader";
import { ButtonDelay, SectionUtility } from "../utils/tools";
import { SetExamAction } from "../reducers/exam/set-exam-action";
import { Services } from "../services";
import Lottie from "react-lottie-player";
import './section-intro.css';
import { ViewList } from "react-bootstrap-icons";
import './anim.css'; 
import * as LottieAnim from "../constants/LottieAnimations";

const Section = () => {
  const [section, setSection] = useState({});
  const egraS3Json = useRef(null)
  const store = useStore()
  const dispatch = useDispatch();
  const [animation,setAnimation] = useState('')
  const [view, setView] = useState(View.LandingScreen)
  const { id } = useParams();
  const buttonSetFlag = useRef(false)
  const ref = useRef(null);
  const sectionData = useRef()
  const clickTheButton = useRef()
  const clickTheButtonWithSilent = useRef()
  const clickButtonCheck = useRef(0)
  const customTimer = useRef()
  const [btnImage,setButtonImage] = useState("/buttons/button_next_default.svg")
  const [hurrayImage, setHurrayImage] = useState("/buttons/hurray.svg")
  const dateDifferenceInSeconds = (dateInitial, dateFinal) => (dateFinal - dateInitial) / 1000;
  const move = async () => {
    setView(View.LottiePlaying)    
    let audiourl = SectionUtility.GetInstructionAudioUrl(egraS3Json.current,section?.pagetype?.id)
    if(section?.pagetype?.id){
      // SectionUtility.AudioPlayer(audiourl, postAudioHandler)
      if(View.LottiedEnded){
        setButtonImage("/buttons/button_next_default.svg")
      }              
        
      var sectionPage = ""
      console.log(section?.pagetype?.id)
      switch(parseInt(section?.pagetype?.id)) {
        case 1:
          // setView(View.LottiedEnded)
         
          sectionPage = Services.Animation.Categories.Listening

          sectionData.current = await Services.Animation.getSectionAssets(sectionPage).then((data) =>{
            return data
          })
          clickTheButton.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3.mp3")
          clickTheButtonWithSilent.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3-with-silence.mp3")
    
          const EN_real_listen1 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_listen1.mp3")  
          const EN_real_listen2 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_listen2.mp3")
          const EN_real_listen3 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_listen3.mp3")
          // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_section1.json"))
          setAnimation(LottieAnim.ANIM_real_section1)
          // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_listen1.json"))
          setAnimation(LottieAnim.ANIM_real_listen1)
          EN_real_listen1.play()
        
          EN_real_listen1.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_listen2.json"))
            setAnimation(LottieAnim.ANIM_real_listen2)
            EN_real_listen2.play()
          })
    
          EN_real_listen2.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_listen3.json"))
            setAnimation(LottieAnim.ANIM_real_listen3)
            EN_real_listen3.play()
          })
    
          EN_real_listen3.addEventListener("ended", (e) =>{
            buttonSetFlag.current = true
            setTimeout(() =>{
              setView(View.LottiedEnded)
              // setAnimation(LottieAnim.ANIM_real_still_book_read)
              setAnimation(LottieAnim.ANIM_real_still_book_read)
              // setView(View.IntroView)
            }, 1000)

            setTimeout(() =>{
              if(!clickButtonCheck.current){
                clickTheButtonWithSilent.current.loop = true
                clickTheButtonWithSilent.current.play()
              }
            }, 3000)
          })
        
    
          break;
        case 2:
          sectionPage = Services.Animation.Categories.Letter
          sectionData.current = await Services.Animation.getSectionAssets(sectionPage).then((data) =>{
            return data
          })
          clickTheButtonWithSilent.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3-with-silence.mp3")
          clickTheButton.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3.mp3")
          const EN_real_letter1 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_letter1.mp3")
          
          const EN_real_letter2 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_letter2.mp3")
          const EN_real_letter3 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_letter3.mp3")
          const EN_real_letter4 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_letter4 .mp3")
          const EN_real_letter5 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_letter5.mp3")
          const EN_real_letter6 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_letter6.mp3")
          const EN_real_letter7 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_letter7.mp3")
          const EN_real_letter8 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_letter8.mp3")
          const EN_real_letter9 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_letter9.mp3")
          const EN_real_letter10 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_letter10.mp3")
          const EN_real_letter11 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_letter11.mp3")
          const EN_real_letter12 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_letter12.mp3")
          const EN_real_letter13 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_letter13.mp3")


          // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_section3.json"))
          setAnimation(LottieAnim.ANIM_real_section3)
          setTimeout(() =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic1.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic1)
            EN_real_letter1.play()
          }, 1000)

          EN_real_letter1.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic2.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic2)
            EN_real_letter2.play()
          })
    
          EN_real_letter2.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic3.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic3)
            EN_real_letter3.play()
          })

          EN_real_letter3.addEventListener("ended", (e) =>{
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter1-T.json"))
              setAnimation(LottieAnim.ANIM_real_letter1_T)
              EN_real_letter4.play()
              setTimeout(() =>{
                // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter2-T.json"))
                setAnimation(LottieAnim.ANIM_real_letter2_T)
              }, 500)
          })

          EN_real_letter4.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter3-T.json"))
            setAnimation(LottieAnim.ANIM_real_letter3_T)
            EN_real_letter5.play()
          })

          EN_real_letter5.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter4-T.json"))
            setAnimation(LottieAnim.ANIM_real_letter4_T)
            
            setTimeout(() =>{
              EN_real_letter6.play()
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5-M.json"))
              setAnimation(LottieAnim.ANIM_real_letter5_M)
            }, 1000)
           
          })

          EN_real_letter6.addEventListener("ended", (e) =>{
            setTimeout(() =>{
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter6-M.json"))
              setAnimation(LottieAnim.ANIM_real_letter6_M)
            }, 500)
            setTimeout(() =>{
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter7-M.json"))
              setAnimation(LottieAnim.ANIM_real_letter7_M)
            }, 500)
            setTimeout(() =>{
              EN_real_letter7.play()
            }, 2000)
            
          })

          EN_real_letter7.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter8-M.json"))
            setAnimation(LottieAnim.ANIM_real_letter8_M)
            
            EN_real_letter8.play()
          })

          EN_real_letter8.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter9-M.json"))
            setAnimation(LottieAnim.ANIM_real_letter9_M)
            setTimeout(() =>{
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5-S.json"))
              setAnimation(LottieAnim.ANIM_real_letter5_S)
            }, 500)
            setTimeout(() =>{
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter6-S.json"))
              setAnimation(LottieAnim.ANIM_real_letter6_S)
            }, 500)
            setTimeout(() =>{
              EN_real_letter9.play()
            }, 2000)
          })

          EN_real_letter9.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter7-S.json"))
            setAnimation(LottieAnim.ANIM_real_letter7_S)
            setTimeout(() =>{
              EN_real_letter10.play()
            }, 2000)
            
          })
          EN_real_letter10.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter10-S.json"))
            setAnimation(LottieAnim.ANIM_real_letter10_S)
            EN_real_letter11.play()
            
          })
          EN_real_letter11.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter11-S.json"))
            setAnimation(LottieAnim.ANIM_real_letter11_S)
            EN_real_letter12.play()
            
          })
          EN_real_letter12.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter12-S.json"))
            setAnimation(LottieAnim.ANIM_real_letter12_S)
            EN_real_letter13.play()
            
          })

          EN_real_letter13.addEventListener("ended", (e) =>{
            buttonSetFlag.current = true
            setTimeout(() =>{
              setView(View.LottiedEnded)
              setAnimation(LottieAnim.ANIM_real_still_book_read)
              // setView(View.IntroView)
            }, 1000)
            setTimeout(() =>{
              if(!clickButtonCheck.current){
                clickTheButtonWithSilent.current.loop = true
                clickTheButtonWithSilent.current.play()
              }
            }, 3000)
          })
         
          
          break;
        case 3:
          sectionPage = Services.Animation.Categories.Nonword
          sectionData.current = await Services.Animation.getSectionAssets(sectionPage).then((data) =>{
            return data
          }).catch((e) => {
            console.log(e)
          })
          clickTheButtonWithSilent.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3-with-silence.mp3")
          clickTheButton.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3.mp3")
          const EN_real_nonword1 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_nonword1.mp3")
          
          const EN_real_nonword2 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_nonword2.mp3")
          const EN_real_nonword3 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_nonword3.mp3")
          const EN_real_nonword4 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_nonword4.mp3")
          const EN_real_nonword5 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_nonword5.mp3")
          const EN_real_nonword6 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_nonword6.mp3")
          const EN_real_nonword7 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_nonword7.mp3")
          const EN_real_nonword8 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_nonword8.mp3")
          const EN_real_nonword9 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_nonword9.mp3")
          const EN_real_nonword10 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_nonword10.mp3")
          const EN_real_nonword11 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_nonword11.mp3")
          const EN_real_nonword12 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_nonword12.mp3")
          const EN_real_nonword13 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_nonword13.mp3")

          // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_section4.json"))
          setAnimation(LottieAnim.ANIM_real_section4)
          setTimeout(() =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic1.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic1)
            EN_real_nonword1.play()
          }, 1000)

          EN_real_nonword1.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic2.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic2)
            EN_real_nonword2.play()
          })
    
          EN_real_nonword2.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic3.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic3)
            EN_real_nonword3.play()
          })

          EN_real_nonword3.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter1-ut.json"))
            setAnimation(LottieAnim.ANIM_real_letter1_ut)
            EN_real_nonword4.play()
            setTimeout(() =>{
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter2-ut.json"))
              setAnimation(LottieAnim.ANIM_real_letter2_ut)
            }, 1000)
          })

          EN_real_nonword4.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter3-ut.json"))
            setAnimation(LottieAnim.ANIM_real_letter3_ut)
            EN_real_nonword5.play()
          })
          EN_real_nonword5.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter4-ut.json"))
            setAnimation(LottieAnim.ANIM_real_letter4_ut)
            EN_real_nonword6.play()
            setTimeout(() =>{
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5-dit.json"))
              setAnimation(LottieAnim.ANIM_real_letter5_dit)
            }, 500)
            setTimeout(() =>{
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter6-dit.json"))
              setAnimation(LottieAnim.ANIM_real_letter6_dit)
            }, 500)
          })

          EN_real_nonword6.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter7-dit.json"))
            setAnimation(LottieAnim.ANIM_real_letter7_dit)
            setTimeout(() =>{
              EN_real_nonword7.play()
            }, 3000)
          })

          EN_real_nonword7.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter8-dit.json"))
            setAnimation(LottieAnim.ANIM_real_letter8_dit)
            EN_real_nonword8.play()
          })
          EN_real_nonword8.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter9-dit.json"))
            setAnimation(LottieAnim.ANIM_real_letter9_dit)
            EN_real_nonword9.play()
            setTimeout(() =>{
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5-mab.json"))
              setAnimation(LottieAnim.ANIM_real_letter5_mab)
            }, 500)
            setTimeout(() =>{
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter6-mab.json"))
              setAnimation(LottieAnim.ANIM_real_letter6_mab)
            }, 500)
          })

          EN_real_nonword9.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter7-mab.json"))
            setAnimation(LottieAnim.ANIM_real_letter7_mab)
            setTimeout(() =>{
              EN_real_nonword10.play()
            }, 3000)
          })
          EN_real_nonword10.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter10-mab.json"))
            setAnimation(LottieAnim.ANIM_real_letter10_mab)
            EN_real_nonword11.play()
          })
          EN_real_nonword11.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter11-mab.json"))
            setAnimation(LottieAnim.ANIM_real_letter11_mab)
            EN_real_nonword12.play()
          })
          EN_real_nonword12.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter12-mab.json"))
            setAnimation(LottieAnim.ANIM_real_letter12_mab)
            EN_real_nonword13.play()
          })
          EN_real_nonword13.addEventListener("ended", (e) =>{
            buttonSetFlag.current = true
            setTimeout(() =>{
              setView(View.LottiedEnded)
              setAnimation(LottieAnim.ANIM_real_still_book_read)
              // setView(View.IntroView)
            }, 1000)
            setTimeout(() =>{
              if(!clickButtonCheck.current){
                clickTheButtonWithSilent.current.loop = true
                clickTheButtonWithSilent.current.play()
              }
            }, 3000)
          })
          
          break;
        case 4:
          sectionPage = Services.Animation.Categories.Familiar
          sectionData.current = await Services.Animation.getSectionAssets(sectionPage).then((data) =>{
            return data
          }).catch((e) => {
            console.log(e)
          })
          clickTheButtonWithSilent.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3-with-silence.mp3")
          clickTheButton.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3.mp3")
          const EN_real_famword1 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_famword1.mp3")
          
          const EN_real_famword2 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_famword2.mp3")
          const EN_real_famword3 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_famword3.mp3")
          const EN_real_famword4 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_famword4.mp3")
          const EN_real_famword5 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_famword5.mp3")
          const EN_real_famword6 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_famword6.mp3")
          const EN_real_famword7 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_famword7.mp3")
          const EN_real_famword8 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_famword8.mp3")
          const EN_real_famword9 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_famword9.mp3")
          const EN_real_famword10 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_famword10.mp3")
          const EN_real_famword11 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_famword11.mp3")
          const EN_real_famword12 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_famword12.mp3")
          const EN_real_famword13 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_famword13.mp3")



          // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_section5.json"))
          setAnimation(LottieAnim.ANIM_real_section5)
          setTimeout(() =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic1.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic1)
            EN_real_famword1.play()
          }, 1000)


          
          EN_real_famword1.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic2.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic2)
            EN_real_famword2.play()
          })
    
          EN_real_famword2.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic3.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic3)
            EN_real_famword3.play()
          })

          EN_real_famword3.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter1-cat.json"))
            setAnimation(LottieAnim.ANIM_real_letter1_cat)
            EN_real_famword4.play()
            setTimeout(() =>{
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter2-cat.json"))
              setAnimation(LottieAnim.ANIM_real_letter2_cat)
            }, 1000)

          })

          EN_real_famword4.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter3-cat.json"))
            setAnimation(LottieAnim.ANIM_real_letter3_cat)
            EN_real_famword5.play()
          })

          EN_real_famword5.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter4-cat.json"))
            setAnimation(LottieAnim.ANIM_real_letter4_cat)
            EN_real_famword6.play()
            setTimeout(() =>{
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5-sick.json"))
              setAnimation(LottieAnim.ANIM_real_letter5_sick)
            }, 500)
            setTimeout(() =>{
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter6-sick.json"))
              setAnimation(LottieAnim.ANIM_real_letter6_sick)
            }, 500)

          })

          EN_real_famword6.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter7-sick.json"))
            setAnimation(LottieAnim.ANIM_real_letter7_sick)
            setTimeout(() =>{
              EN_real_famword7.play()
            }, 3000)
          })

          EN_real_famword7.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter8-sick.json"))
            setAnimation(LottieAnim.ANIM_real_letter8_sick)
            EN_real_famword8.play()
          })
          EN_real_famword8.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter9-sick.json"))
            setAnimation(LottieAnim.ANIM_real_letter9_sick)
            EN_real_famword9.play()
            setTimeout(() =>{
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter5-made.json"))
              setAnimation(LottieAnim.ANIM_real_letter5_made)
            }, 500)
            setTimeout(() =>{
              // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter6-made.json"))
              setAnimation(LottieAnim.ANIM_real_letter6_made)
            }, 500)
          })

          EN_real_famword9.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter7-made.json"))
            setAnimation(LottieAnim.ANIM_real_letter7_made)
            setTimeout(() =>{
              EN_real_famword10.play()
            }, 3000)
          })
          EN_real_famword10.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter10-made.json"))
            setAnimation(LottieAnim.ANIM_real_letter10_made)
            EN_real_famword11.play()
          })
          EN_real_famword11.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter11-made.json"))
            setAnimation(LottieAnim.ANIM_real_letter11_made)
            EN_real_famword12.play()
          })
          EN_real_famword12.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter12-made.json"))
            setAnimation(LottieAnim.ANIM_real_letter12_made)
            EN_real_famword13.play()
          })
          EN_real_famword13.addEventListener("ended", (e) =>{
            buttonSetFlag.current = true
            setTimeout(() =>{
              setView(View.LottiedEnded)
              setAnimation(LottieAnim.ANIM_real_still_book_read)
              // setView(View.IntroView)
            }, 1000)
            setTimeout(() =>{
              if(!clickButtonCheck.current){
                clickTheButtonWithSilent.current.loop = true
                clickTheButtonWithSilent.current.play()
              }
            }, 3000)
            
          })

          break;
        case 5:
          sectionPage = Services.Animation.Categories.Comprehension

          sectionData.current = await Services.Animation.getSectionAssets(sectionPage).then((data) =>{
            return data
          }).catch((e) => {
            console.log(e)
          })
          clickTheButtonWithSilent.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3-with-silence.mp3")
          const EN_real_fluency1 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_fluency1.mp3")
          
          const EN_real_fluency2 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_fluency2.mp3")
          const EN_real_fluency3 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_fluency3.mp3")
          const EN_real_fluency4 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_fluency4.mp3")
          const EN_real_fluency5 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_fluency5.mp3")


          // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_section6.json"))
          setAnimation(LottieAnim.ANIM_real_section6)
          setTimeout(() =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_fluency1.json"))
            setAnimation(LottieAnim.ANIM_real_fluency1)
            EN_real_fluency1.play()
          }, 1000)


          
          EN_real_fluency1.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_fluency2.json"))
            setAnimation(LottieAnim.ANIM_real_fluency2)
            EN_real_fluency2.play()
          })
    
          EN_real_fluency2.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_fluency3.json"))
            setAnimation(LottieAnim.ANIM_real_fluency3)
            EN_real_fluency3.play()
          })

          EN_real_fluency3.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_fluency4.json"))
            setAnimation(LottieAnim.ANIM_real_fluency4)
            EN_real_fluency4.play()

          })
          EN_real_fluency4.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_fluency5.json"))
            setAnimation(LottieAnim.ANIM_real_fluency5)
            EN_real_fluency5.play()

          })

          EN_real_fluency5.addEventListener("ended", () => {
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_fluency6.json"))
            setAnimation(LottieAnim.ANIM_real_fluency6)
            buttonSetFlag.current = true
            setTimeout(() =>{
              setAnimation(LottieAnim.ANIM_real_still_book_read)
              // setAnimation(LottieAnim.ANIM_real_still_book_read)
              setView(View.LottiedEnded)
              // setView(View.IntroView)
            }, 1000)
            setTimeout(() =>{
              if(!clickButtonCheck.current){
                clickTheButtonWithSilent.current.loop = true
                clickTheButtonWithSilent.current.play()
              }
            }, 3000)
          })

        
          break;
        case 6:
          sectionPage = Services.Animation.Categories.Comprehension
          
          sectionData.current = await Services.Animation.getSectionAssets(sectionPage).then((data) =>{
            return data
          }).catch((e) =>{
            console.log(e)
          })
          clickTheButton.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3.mp3")
          const EN_real_comp1 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_comp1.mp3")

          // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_section6.json"))
          setAnimation(LottieAnim.ANIM_real_section6)
          setTimeout(() =>{
            EN_real_comp1.play()
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_letter7.json"))
            setAnimation(LottieAnim.ANIM_real_letter7)
          }, 1000)

          clickTheButtonWithSilent.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3-with-silence.mp3")

          EN_real_comp1.addEventListener("ended", () => {
            buttonSetFlag.current = true
            setTimeout(() =>{
              setView(View.LottiedEnded)
              setAnimation(LottieAnim.ANIM_real_still_book_read)
              // setView(View.IntroView)
            }, 1000)
            setTimeout(() =>{
              if(!clickButtonCheck.current){
                clickTheButtonWithSilent.current.loop = true
                clickTheButtonWithSilent.current.play()
              }
            }, 3000)
          })
         
          break;
        case 7:
          // buttonSetFlag.current = true
          // setView(View.LottiedEnded)

          sectionPage = Services.Animation.Categories.Phonemic

          sectionData.current = await Services.Animation.getSectionAssets(sectionPage).then((data) =>{
            return data
          }).catch((e) =>{
            console.log(e)
          })
    
          clickTheButton.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3.mp3")
          clickTheButtonWithSilent.current = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_err3-with-silence.mp3")
          const EN_real_phonemic1 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_phonemic1.mp3")
          
          const EN_real_phonemic2 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_phonemic2.mp3")
          const EN_real_phonemic3 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_phonemic3.mp3")
          const EN_real_phonemic4 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_phonemic4.mp3")
          const EN_real_phonemic5 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_phonemic5.mp3")
          const EN_real_phonemic6 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_phonemic6.mp3")
          const EN_real_phonemic7 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_phonemic7.mp3")

          // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic1.json"))
          setAnimation(LottieAnim.ANIM_real_phonemic1)
          // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_section2.json"))
          setAnimation(LottieAnim.ANIM_real_section2)
          setTimeout(() =>{
            EN_real_phonemic1.play()
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic1.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic1)
          }, 1000)

          EN_real_phonemic1.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic2.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic2)
            EN_real_phonemic2.play()
          })
    
          EN_real_phonemic2.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic3.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic3)
            EN_real_phonemic3.play()
          })

          EN_real_phonemic3.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic4.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic4)
            EN_real_phonemic4.play()
          })

          EN_real_phonemic4.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic5.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic5)
            setTimeout(() =>{
              EN_real_phonemic5.play()
            }, 3000)
            
          })
          EN_real_phonemic5.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic4.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic4)
            EN_real_phonemic6.play()
          })
          EN_real_phonemic6.addEventListener("ended", (e) =>{
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic5.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic5)
            
            setTimeout(() =>{
              EN_real_phonemic7.play()
            }, 3000)
          })

          const EN_real_phonemic8 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_phonemic8.mp3")
          const EN_real_phonemic9 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_phonemic9.mp3")
          const EN_real_phonemic10 = Services.Animation.getPreloadedAudio(sectionData.current, "EN_real_phonemic10.mp3")
          EN_real_phonemic7.addEventListener("ended", (e) =>{
            EN_real_phonemic8.play()
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic6.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic6)
          })
          EN_real_phonemic8.addEventListener("ended", () => {
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic7.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic7)
            EN_real_phonemic9.play()
          })
          EN_real_phonemic9.addEventListener("ended", () => {
            // setAnimation(Services.Animation.getLottieAnimation(sectionData.current, "ANIM_real_phonemic8.json"))
            setAnimation(LottieAnim.ANIM_real_phonemic8)
            EN_real_phonemic10.play()
          })

          EN_real_phonemic10.addEventListener("ended", () => {
            buttonSetFlag.current = true
            setTimeout(() =>{
              setView(View.LottiedEnded)
              setAnimation(LottieAnim.ANIM_real_still_book_read)
              // setView(View.IntroView)
            }, 1000)
            setTimeout(() =>{
              if(!clickButtonCheck.current){
                clickTheButtonWithSilent.current.loop = true
                clickTheButtonWithSilent.current.play()
              }
            }, 3000)
          })
          break;
      }
      
     

    }
  };
   const postAudioHandler = () => {    
    setView(View.LottiedEnded)
  }
  useEffect(() => {    
    if(view===View.LottiePlaying){
      ref.current.DisableButton();
    }

    if (store.getState().exam?.urlprefix) {
      setSection({ ...section, pagetype: SectionUtility.DefaultSection });
    }

    const awaitdata = async ()=>{
      const response = await Services.Exam.getExam()
      const data = await response.json()
      dispatch(SetExamAction({payload:data}))
      egraS3Json.current = data
      const section = data.sections.find((x) => {
        return x.section === parseInt(id);
      });
      setSection({
        ...section,pagetype: SectionUtility.GetSectionDetails(id)
      });
    }
    awaitdata().catch((e)=>{
      console.log("await err",e)
    })
  }, []);  

  const IntroAnim = () => {
    return (
      <>
     
      <Lottie
        play={buttonSetFlag.current === true ? false : true}
        loop={false}
        animationData={animation}
        path=""
        style={{
          // width: "200px",
          // height: "300px",
          //background: "#86b7fe",p
          display: "inline-grid",
          placeContent: "center",
        }}
      />
      </>

    );
  }; 
  const NextButtonAnim = () => {
    return (
      <>
     
      <Lottie
        play={false}
        loop={false}
        animationData={animation}
        path=""
        style={{
          // width: "200px",
          // height: "300px",
          //background: "#86b7fe",
          display: "inline-grid",
          placeContent: "center",
        }}
      />
      </>

    );
  };
  const gem = Services.Animation.getGemValue(section?.section)
  


  const StartButton = ({handler}) => {
    return (
      <div
        style={{
          zIndex : 99,
          position: "relative",
          top: buttonSetFlag.current === true ? "" : "86%",
          left: "50%",
          transform: "translateX(-50%) translateY(-50%)",
        }}
      >
        {/* {view === View.LottiedEnded && (
          <img src={hurrayImage} style={{ width: "400px" }} />
        )} */}
        <img
          src={btnImage}
          style={{ width: "150px" }}
          onClick={() => {
            setButtonImage("/buttons/button_next_pressed.svg");
            setTimeout(() => {
              handler();
            }, 500);
          }}
        />
      </div>
    );
  };

  if (section?.pagetype) {
    //used only for debugging purpose
    // return(<Reader egraS3Json={egraS3Json.current} section={section?.pagetype}/>)
    return (
      <>
        <NavBar />
        
        {(view === View.LandingScreen) && <Frame GemValue={gem} content={<StartButton handler={move}/>}  ref={ref}  handler={null} />}
        {/* {(view === View.LottiePlaying) && [1,5,6,7].includes(section?.pagetype?.id) && <Frame content={<LottieFirstPage />} s3egrajson={egraS3Json.current} selectedSection={section} 
        ref={ref}  />} */}
        {view === View.LottiePlaying && <Frame GemValue={gem} content={<IntroAnim />} ref={ref} handler={null} />}

        {/*{(view === View.LottiePlaying) && [2,3,4].includes(section?.pagetype?.id) && <Frame content={<LottieAnimationSequence s3egrajson={egraS3Json.current} selectedSection={SectionUtility.GetEgra3Section(egraS3Json.current,section?.pagetype?.id)} />}  ref={ref}  />} */}
        
        {/* {(view === View.LottiedEnded) && <Frame content={<LottieFirstPage />} ref={ref} handler={() => { */}
        {(view === View.LottiedEnded) && <Frame GemValue={gem} content={
          <>
           <div className="container">
            <div className="item">
              {/* <div> */}
              <IntroAnim />
              <StartButton  handler={() => {
                // clickTheButton.current.addEventListener("timeupdate", (e) =>{
                //   clickTheButton.current.pause()
                // })

                clickButtonCheck.current = 1
                clickTheButtonWithSilent.current.loop = false
                clickTheButtonWithSilent.current.pause()
                clickTheButtonWithSilent.current.currentTime = 0;

                setView(View.IntroView)
                // setTimeout(() => {            
                // }, ButtonDelay);
              }}
            />
          {/* </div> */}
          </div>
        </div>
        </>
        } ref={ref} handler={null} />}
        {(view === View.IntroView) && <Reader egraS3Json={egraS3Json.current} sectionData={sectionData.current} section={section?.pagetype}/>}
        <div style={{ textAlign: "-webkit-center" }}>         
          <a
            id="app-link"
            className="btn btn-lg btn-success mt-3"
            href={section?.pagetype?.url}
            style={{
              borderRadius: "50px",
              display: "none",
              minWidth: "200px",
              maxWidth: "200px",
            }}
          >
            <FontAwesomeIcon icon={faCircleCheck} size="2xl" />
          </a>
        </div>
      </>
    );
  }

  return "";
};

export default Section;
import { MCQNextReducer, MCQNextRdxConst, MCQNextAnimationRdxConst, MCQNextAnimationReducer } from "./mcq-next-action";
import { SetSectionInfoReducer, SetSectionInfoRdxConst } from "./section";

const initial = {
  pagetype: null,
  section: null,
  TimeStampStatus: "NotProcessed",
  recordState: null,
  hidewelcomescreen: false,
  visibility: false,
  audioData: null,
  showList: false,
  files: [],
  counter: 0,
  letter: "",
  pages: [],
  btnText: "Next",
  btnClass: "btn btn-lg btn-success",
  recordings: [],
  animationcounter: 1
};
export const mcqReducer = (state = initial, action) => {
  switch (action.type) {
    case MCQNextAnimationRdxConst:
      return MCQNextAnimationReducer(state, action)
    case MCQNextRdxConst:
      return MCQNextReducer(state, action);
    case SetSectionInfoRdxConst:
      return SetSectionInfoReducer(state, action);
    default:
      return state;
  }
};

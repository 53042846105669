import React,{useEffect, useState} from "react";
import NavBar from "../components/NavBar/NavBar";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { Button } from "bootstrap";
import { Services } from "../services";
import { Link } from "react-router-dom";

export const ChangePassword = () => {
    const [formData, setFormData] = useState({
        newpassword:"",
        message: "",
      });  
      const [currentPIN,setCurrentPIN] = useState()
      useEffect(()=>{
        if(Services.User.isTeacher()){
          Services.Exam.getListOfStudents(Services.User.getLocalStorageDetails()?.schoolUuid).then((data)=>{
            const s = new Set(data.map((x)=>{return x.pin}))
            setCurrentPIN(Array.from(s).join(","))
          })
        }
      },[])
      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
      };
      const handleSubmit = (event) => {
        event.preventDefault();
        
        const details = Services.User.getLocalStorageDetails()
        let payload = {...formData,...details,password:formData.newpassword,confirmPassword:formData.newpassword}
        delete payload.newpassword
        delete payload.message
        delete payload.name
        payload = {...payload,schoolUuid:Services.User.isModerator()?null:payload?.schoolUuid}
        Services.User.ChangePassword(payload).then((response)=>{
          event.target.reset()  
          document.getElementById("success-msg").style.visibility = "visible"
          setTimeout(() => {
            document.getElementById("success-msg").style.visibility = "hidden"
          }, 2000);
        }).catch((e)=>{

        })

      };
    
  return (
    <>
      <NavBar />

      <div className="App-container">
        <div className="App-page-title">Change Password{Services.User.isTeacher() && "/PIN"}</div>
        {Services.User.isTeacher() && (
          <div
            style={{
              marginBottom: "1em",
              padding: "1em",
              border: "1px solid green",
            }}
          >
            Current Pin:
            <span style={{ marginLeft: "1em", marginRight: "4em" }}>
              {currentPIN}
            </span>
            <Link
              onClick={() => {
                setCurrentPIN("-");
                const payload = {
                  teacherUuid: Services.User.getLocalStorageDetails()?.userUuid,
                };
                Services.User.WholeClassPINReset(payload).then((response) => {
                  setCurrentPIN(
                    Array.from(
                      new Set(
                        response.map((x) => {
                          return x.pin;
                        })
                      )
                    ).join(",")
                  );
                });
              }}
            >
              Reset PIN{" "}
            </Link>
          </div>
        )}
        <div
          className="mb-4 p-2"
          style={{ border: "2px solid green", visibility: "hidden" }}
          id="success-msg"
        >
          Your password has been changed.
        </div>

        <Form onSubmit={handleSubmit}>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalSchool"
          >
            <Form.Label column sm={4}>
              School
            </Form.Label>
            <Col sm={8} style={{ textAlign: "left" }}>
              {Services.User.getLocalStorageDetails()?.schoolName}
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalSchool"
          >
            <Form.Label column sm={4}>
              Email
            </Form.Label>
            <Col sm={8} style={{ textAlign: "left" }}>
              {Services.User.getLocalStorageDetails()?.email}
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalSchool"
          >
            <Form.Label column sm={4}>
              Username
            </Form.Label>
            <Col sm={8} style={{ textAlign: "left" }}>
              {Services.User.getLocalStorageDetails()?.username}
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalSchool"
          >
            <Form.Label column sm={4}>
              New Password
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                placeholder=""
                onChange={handleChange}
                name="newpassword"
                required={true}
                minLength={6}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Col sm={{ span: 10, offset: 2 }}>
              <button className="btn btn-primary">Change Password</button>
            </Col>
          </Form.Group>
        </Form>
      </div>
    </>
  );
};

import { createAction } from "redux-actions";

// create constant
export const SetExamRdxConst = "Exam/SetExam";

// create action
export const SetExamAction = createAction(
  SetExamRdxConst,
  (payload) => payload
);

// Create reducer
export const SetExamReducer = (state, action) => {
  return {...action?.payload?.payload};
};

export const upload_sample= require('./lottie/upload-sample.json')
export const ANIM_real_section6= require('./lottie/ANIM_real_section6.json')
export const ANIM_real_still_book_read= require('./lottie/ANIM_real_still-book-read.json')
export const ANIM_real_section5= require('./lottie/ANIM_real_section5.json')
export const ANIM_real_section4= require('./lottie/ANIM_real_section4.json')
export const ANIM_real_section3= require('./lottie/ANIM_real_section3.json')
export const ANIM_real_section1= require('./lottie/ANIM_real_section1.json')
export const ANIM_real_section2= require('./lottie/ANIM_real_section2.json')
export const ANIM_real_phonemic9= require('./lottie/ANIM_real_phonemic9.json')
export const ANIM_real_phonemic8= require('./lottie/ANIM_real_phonemic8.json')
// export const ANIM_real_phonemic8 (2)= require('./lottie/ANIM_real_phonemic8 (2).json')
// export const ANIM_real_phonemic8 (1)= require('./lottie/ANIM_real_phonemic8 (1).json')
export const ANIM_real_phonemic7= require('./lottie/ANIM_real_phonemic7.json')
export const ANIM_real_phonemic5= require('./lottie/ANIM_real_phonemic5.json')
export const ANIM_real_phonemic6= require('./lottie/ANIM_real_phonemic6.json')
export const ANIM_real_phonemic4= require('./lottie/ANIM_real_phonemic4.json')
export const ANIM_real_phonemic3= require('./lottie/ANIM_real_phonemic3.json')
export const ANIM_real_phonemic2= require('./lottie/ANIM_real_phonemic2.json')
export const ANIM_real_phonemic13= require('./lottie/ANIM_real_phonemic13.json')
export const ANIM_real_phonemic11= require('./lottie/ANIM_real_phonemic11.json')
export const ANIM_real_phonemic12= require('./lottie/ANIM_real_phonemic12.json')
export const ANIM_real_phonemic10= require('./lottie/ANIM_real_phonemic10.json')
export const ANIM_real_phonemic1= require('./lottie/ANIM_real_phonemic1.json')
export const ANIM_real_loading_white= require('./lottie/ANIM_real_loading-white.json')
export const ANIM_real_listen9= require('./lottie/ANIM_real_listen9.json')
export const ANIM_real_listen8= require('./lottie/ANIM_real_listen8.json')
export const ANIM_real_listen7= require('./lottie/ANIM_real_listen7.json')
export const ANIM_real_listen6= require('./lottie/ANIM_real_listen6.json')
export const ANIM_real_listen5= require('./lottie/ANIM_real_listen5.json')
export const ANIM_real_listen4= require('./lottie/ANIM_real_listen4.json')
export const ANIM_real_listen3= require('./lottie/ANIM_real_listen3.json')
export const ANIM_real_listen2= require('./lottie/ANIM_real_listen2.json')
export const ANIM_real_listen12= require('./lottie/ANIM_real_listen12.json')
export const ANIM_real_listen13= require('./lottie/ANIM_real_listen13.json')
export const ANIM_real_listen11= require('./lottie/ANIM_real_listen11.json')
export const ANIM_real_listen10= require('./lottie/ANIM_real_listen10.json')
export const ANIM_real_listen1= require('./lottie/ANIM_real_listen1.json')
export const ANIM_real_letter9= require('./lottie/ANIM_real_letter9.json')
export const ANIM_real_letter9_sick= require('./lottie/ANIM_real_letter9-sick.json')
export const ANIM_real_letter9_M= require('./lottie/ANIM_real_letter9-M.json')
export const ANIM_real_letter9_dit= require('./lottie/ANIM_real_letter9-dit.json')
export const ANIM_real_letter8_sick= require('./lottie/ANIM_real_letter8-sick.json')
export const ANIM_real_letter8= require('./lottie/ANIM_real_letter8.json')
export const ANIM_real_letter8_dit= require('./lottie/ANIM_real_letter8-dit.json')
export const ANIM_real_letter8_M= require('./lottie/ANIM_real_letter8-M.json')
export const ANIM_real_letter7= require('./lottie/ANIM_real_letter7.json')
export const ANIM_real_letter7_sick= require('./lottie/ANIM_real_letter7-sick.json')
export const ANIM_real_letter7_made= require('./lottie/ANIM_real_letter7-made.json')
export const ANIM_real_letter7_mab= require('./lottie/ANIM_real_letter7-mab.json')
export const ANIM_real_letter7_dit= require('./lottie/ANIM_real_letter7-dit.json')
export const ANIM_real_letter7_S= require('./lottie/ANIM_real_letter7-S.json')
export const ANIM_real_letter6= require('./lottie/ANIM_real_letter6.json')
export const ANIM_real_letter7_M= require('./lottie/ANIM_real_letter7-M.json')
export const ANIM_real_letter6_x= require('./lottie/ANIM_real_letter6-x.json')
export const ANIM_real_letter6_w= require('./lottie/ANIM_real_letter6-w.json')
export const ANIM_real_letter6_u= require('./lottie/ANIM_real_letter6-u.json')
export const ANIM_real_letter6_v= require('./lottie/ANIM_real_letter6-v.json')
// export const ANIM_real_letter6_t (2)= require('./lottie/ANIM_real_letter6-t (2).json')
// export const ANIM_real_letter6_t (1)= require('./lottie/ANIM_real_letter6-t (1).json')
export const ANIM_real_letter6_sick= require('./lottie/ANIM_real_letter6-sick.json')
// export const ANIM_real_letter6_s (2)= require('./lottie/ANIM_real_letter6-s (2).json')
export const ANIM_real_letter6_r= require('./lottie/ANIM_real_letter6-r.json')
export const ANIM_real_letter6_q= require('./lottie/ANIM_real_letter6-q.json')
export const ANIM_real_letter6_p= require('./lottie/ANIM_real_letter6-p.json')
export const ANIM_real_letter6_made= require('./lottie/ANIM_real_letter6-made.json')
export const ANIM_real_letter6_n= require('./lottie/ANIM_real_letter6-n.json')
export const ANIM_real_letter6_mab= require('./lottie/ANIM_real_letter6-mab.json')
//export const ANIM_real_letter6_m (2)= require('./lottie/ANIM_real_letter6-m (2).json')
export const ANIM_real_letter6_l= require('./lottie/ANIM_real_letter6-l.json')
export const ANIM_real_letter6_k= require('./lottie/ANIM_real_letter6-k.json')
export const ANIM_real_letter6_j2= require('./lottie/ANIM_real_letter6-j2.json')
export const ANIM_real_letter6_h= require('./lottie/ANIM_real_letter6-h.json')
export const ANIM_real_letter6_i= require('./lottie/ANIM_real_letter6-i.json')
export const ANIM_real_letter6_f= require('./lottie/ANIM_real_letter6-f.json')
export const ANIM_real_letter6_dit= require('./lottie/ANIM_real_letter6-dit.json')
export const ANIM_real_letter6_e= require('./lottie/ANIM_real_letter6-e.json')
export const ANIM_real_letter6_c= require('./lottie/ANIM_real_letter6-c.json')
export const ANIM_real_letter6_d= require('./lottie/ANIM_real_letter6-d.json')
export const ANIM_real_letter6_b= require('./lottie/ANIM_real_letter6-b.json')
export const ANIM_real_letter6_a= require('./lottie/ANIM_real_letter6-a.json')
export const ANIM_real_letter6_Z= require('./lottie/ANIM_real_letter6-Z.json')
export const ANIM_real_letter6_Y= require('./lottie/ANIM_real_letter6-Y.json')
export const ANIM_real_letter6_T= require('./lottie/ANIM_real_letter6-T.json')
export const ANIM_real_letter6_S= require('./lottie/ANIM_real_letter6-S.json')
export const ANIM_real_letter6_O= require('./lottie/ANIM_real_letter6-O.json')
export const ANIM_real_letter6_M= require('./lottie/ANIM_real_letter6-M.json')
export const ANIM_real_letter6_J1= require('./lottie/ANIM_real_letter6-J1.json')
export const ANIM_real_letter6_G= require('./lottie/ANIM_real_letter6-G.json')
export const ANIM_real_letter5_sick= require('./lottie/ANIM_real_letter5-sick.json')
export const ANIM_real_letter5= require('./lottie/ANIM_real_letter5.json')
export const ANIM_real_letter5_made= require('./lottie/ANIM_real_letter5-made.json')
export const ANIM_real_letter5_mab= require('./lottie/ANIM_real_letter5-mab.json')
export const ANIM_real_letter5_dit= require('./lottie/ANIM_real_letter5-dit.json')
export const ANIM_real_letter5_S= require('./lottie/ANIM_real_letter5-S.json')
export const ANIM_real_letter5_M= require('./lottie/ANIM_real_letter5-M.json')
export const ANIM_real_letter4= require('./lottie/ANIM_real_letter4.json')
export const ANIM_real_letter4_ut= require('./lottie/ANIM_real_letter4-ut.json')
export const ANIM_real_letter4_cat= require('./lottie/ANIM_real_letter4-cat.json')
export const ANIM_real_letter4_T= require('./lottie/ANIM_real_letter4-T.json')
export const ANIM_real_letter3_ut= require('./lottie/ANIM_real_letter3-ut.json')
export const ANIM_real_letter3= require('./lottie/ANIM_real_letter3.json')
export const ANIM_real_letter3_cat= require('./lottie/ANIM_real_letter3-cat.json')
export const ANIM_real_letter3_T= require('./lottie/ANIM_real_letter3-T.json')
export const ANIM_real_letter2= require('./lottie/ANIM_real_letter2.json')
export const ANIM_real_letter2_ut= require('./lottie/ANIM_real_letter2-ut.json')
export const ANIM_real_letter2_cat= require('./lottie/ANIM_real_letter2-cat.json')
export const ANIM_real_letter17= require('./lottie/ANIM_real_letter17.json')
export const ANIM_real_letter2_T= require('./lottie/ANIM_real_letter2-T.json')
export const ANIM_real_letter16= require('./lottie/ANIM_real_letter16.json')
export const ANIM_real_letter15= require('./lottie/ANIM_real_letter15.json')
export const ANIM_real_letter14= require('./lottie/ANIM_real_letter14.json')
export const ANIM_real_letter13_t= require('./lottie/ANIM_real_letter13-t.json')
export const ANIM_real_letter13= require('./lottie/ANIM_real_letter13.json')
export const ANIM_real_letter12= require('./lottie/ANIM_real_letter12.json')
export const ANIM_real_letter12_made= require('./lottie/ANIM_real_letter12-made.json')
export const ANIM_real_letter12_mab= require('./lottie/ANIM_real_letter12-mab.json')
export const ANIM_real_letter11= require('./lottie/ANIM_real_letter11.json')
export const ANIM_real_letter12_S= require('./lottie/ANIM_real_letter12-S.json')
export const ANIM_real_letter11_made= require('./lottie/ANIM_real_letter11-made.json')
export const ANIM_real_letter11_mab= require('./lottie/ANIM_real_letter11-mab.json')
export const ANIM_real_letter11_S= require('./lottie/ANIM_real_letter11-S.json')
export const ANIM_real_letter10= require('./lottie/ANIM_real_letter10.json')
export const ANIM_real_letter10_made= require('./lottie/ANIM_real_letter10-made.json')
export const ANIM_real_letter10_mab= require('./lottie/ANIM_real_letter10-mab.json')
export const ANIM_real_letter10_S= require('./lottie/ANIM_real_letter10-S.json')
export const ANIM_real_letter1_ut= require('./lottie/ANIM_real_letter1-ut.json')
export const ANIM_real_letter1= require('./lottie/ANIM_real_letter1.json')
export const ANIM_real_letter1_cat= require('./lottie/ANIM_real_letter1-cat.json')
export const ANIM_real_letter1_T= require('./lottie/ANIM_real_letter1-T.json')
export const ANIM_real_intro9= require('./lottie/ANIM_real_intro9.json')
export const ANIM_real_intro7= require('./lottie/ANIM_real_intro7.json')
export const ANIM_real_intro8= require('./lottie/ANIM_real_intro8.json')
export const ANIM_real_intro6= require('./lottie/ANIM_real_intro6.json')
export const ANIM_real_intro5= require('./lottie/ANIM_real_intro5.json')
export const ANIM_real_intro4= require('./lottie/ANIM_real_intro4.json')
export const ANIM_real_intro3= require('./lottie/ANIM_real_intro3.json')
export const ANIM_real_intro2= require('./lottie/ANIM_real_intro2.json')
export const ANIM_real_intro1= require('./lottie/ANIM_real_intro1.json')
export const ANIM_real_gem6= require('./lottie/ANIM_real_gem6.json')
export const ANIM_real_gem6_sparkle= require('./lottie/ANIM_real_gem6-sparkle.json')
export const ANIM_real_gem5= require('./lottie/ANIM_real_gem5.json')
export const ANIM_real_gem5_sparkle= require('./lottie/ANIM_real_gem5-sparkle.json')
export const ANIM_real_gem4= require('./lottie/ANIM_real_gem4.json')
export const ANIM_real_gem4_sparkle= require('./lottie/ANIM_real_gem4-sparkle.json')
export const ANIM_real_gem3= require('./lottie/ANIM_real_gem3.json')
export const ANIM_real_gem3_sparkle= require('./lottie/ANIM_real_gem3-sparkle.json')
export const ANIM_real_gem2= require('./lottie/ANIM_real_gem2.json')
export const ANIM_real_gem2_sparkle= require('./lottie/ANIM_real_gem2-sparkle.json')
export const ANIM_real_gem1= require('./lottie/ANIM_real_gem1.json')
export const ANIM_real_gem1_sparkle= require('./lottie/ANIM_real_gem1-sparkle.json')
export const ANIM_real_fluency6= require('./lottie/ANIM_real_fluency6.json')
export const ANIM_real_fluency5= require('./lottie/ANIM_real_fluency5.json')
export const ANIM_real_fluency4= require('./lottie/ANIM_real_fluency4.json')
export const ANIM_real_fluency3= require('./lottie/ANIM_real_fluency3.json')
export const ANIM_real_fluency2= require('./lottie/ANIM_real_fluency2.json')
export const ANIM_real_fluency1= require('./lottie/ANIM_real_fluency1.json')
export const ANIM_real_err9= require('./lottie/ANIM_real_err9.json')
export const ANIM_real_err7= require('./lottie/ANIM_real_err7.json')
export const ANIM_real_err8= require('./lottie/ANIM_real_err8.json')
export const ANIM_real_err6= require('./lottie/ANIM_real_err6.json')
export const ANIM_real_err5= require('./lottie/ANIM_real_err5.json')
export const ANIM_real_err4= require('./lottie/ANIM_real_err4.json')
export const ANIM_real_err3= require('./lottie/ANIM_real_err3.json')
export const ANIM_real_err2= require('./lottie/ANIM_real_err2.json')
export const ANIM_real_err12= require('./lottie/ANIM_real_err12.json')
export const ANIM_real_err10= require('./lottie/ANIM_real_err10.json')
export const ANIM_real_err11= require('./lottie/ANIM_real_err11.json')
export const ANIM_real_err1= require('./lottie/ANIM_real_err1.json')
export const ANIM_real_err_replace9= require('./lottie/ANIM_real_err-replace9.json')
export const ANIM_real_err_replace7= require('./lottie/ANIM_real_err-replace7.json')
export const ANIM_real_err_replace8= require('./lottie/ANIM_real_err-replace8.json')
export const ANIM_real_err_replace6= require('./lottie/ANIM_real_err-replace6.json')
export const ANIM_real_err_replace4= require('./lottie/ANIM_real_err-replace4.json')
export const ANIM_real_err_replace5= require('./lottie/ANIM_real_err-replace5.json')
export const ANIM_real_err_replace3= require('./lottie/ANIM_real_err-replace3.json')
export const ANIM_real_err_replace2= require('./lottie/ANIM_real_err-replace2.json')
export const ANIM_real_err_replace1= require('./lottie/ANIM_real_err-replace1.json')
export const ANIM_real_comp3= require('./lottie/ANIM_real_comp3.json')
export const ANIM_real_comp1= require('./lottie/ANIM_real_comp1.json')
export const ANIM_real_comp2= require('./lottie/ANIM_real_comp2.json')
export const ANIM_real_button_start2= require('./lottie/ANIM_real_button-start2.json')
export const ANIM_real_button_start1= require('./lottie/ANIM_real_button-start1.json')
export const ANIM_real_button_start= require('./lottie/ANIM_real_button-start.json')
export const ANIM_real_button_prompt2= require('./lottie/ANIM_real_button-prompt2.json')
export const ANIM_real_button_prompt1= require('./lottie/ANIM_real_button-prompt1.json')
export const ANIM_real_button_emphasis= require('./lottie/ANIM_real_button-emphasis.json')
export const ANIM_real_button_check2= require('./lottie/ANIM_real_button-check2.json')
export const ANIM_real_button_check1= require('./lottie/ANIM_real_button-check1.json')
export const ANIM_real_button_check= require('./lottie/ANIM_real_button-check.json')
//export const ANIM_real_still_middle_prompt= require('./lottie/ANIM_real_still-middle-prompt.json')
//export const ANIM_real_still_book_listen= require('./lottie/ANIM_real_still-book-listen.json')
//export const ANIM_real_still_book_hurray= require('./lottie/ANIM_real_still-book-hurray.json')
//export const ANIM_real_still_book_prompt= require('./lottie/ANIM_real_still-book-prompt.json')
//export const ANIM_real_still_corner_prompt= require('./lottie/ANIM_real_still-corner-prompt.json')

import React, { useState } from "react";
import NavBar from "../components/NavBar/NavBar";
import { Services } from "../services";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import { VERSION } from "../environments/version";
export const Login = () => {
  const loginTypes = {Teacher:"teacher",Student:"student"}
  const [disabled, setDisabled] = useState(false);
  const [loginType,setLoginType] = useState(loginTypes.Teacher)
  const [message,setMessage] = useState()
  let formdata = { email: "",password:"",schoolPin:"",studentPin:"" };
  const navigate = useNavigate()
  const Login = ()=>{
    let url = null
    const invalidLoginMessage ="Incorrect username or password" 
      console.log(formdata)
    if(loginType === loginTypes.Student){
      Services.User.CompetitionStudentLogin(formdata).then((response)=>{
        url = "/"
        if(response?.message){
          setMessage(response.message)     
        } else {
          localStorage.setItem('user',JSON.stringify(response))
          const audioPlay = new Audio("/animation-assets/en/SFX_real_theme.mp3");
          audioPlay.play().catch((e) => {
            console.log(e, "audio play error")
          })
          navigate(url)
        }
      }).catch((e)=>{
        console.log(e)
      })
    } else if(loginType === loginTypes.Teacher) {
      Services.User.CompetitionTeacherLogin(formdata).then((response)=>{
        url = "/list-recordings"
        if(response?.message){
          setMessage(response.message)     
        } else {
          localStorage.setItem('user',JSON.stringify(response))
          navigate(url)
        }
      }).catch((e)=>{
        console.log(e)
      })
    }

    ///navigate(url)
  }

  const TeacherForm = () => {
    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          const email = e.currentTarget.elements.namedItem("email").value;
          const password = e.currentTarget.elements.namedItem("password").value;
          formdata = {...formdata,email:email,password:password}
          Login()
        }}
      >
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalSchool">
          <Form.Label column sm={3}>
            Email
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="email"
              placeholder=""
              onChange={(e) => {
                formdata = { ...formdata, email: e.target.value };
              }}
              name="email"
              required={true}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalSchool">
          <Form.Label column sm={3}>
            Password
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="password"
              placeholder=""
              onChange={(e) => {
                formdata = { ...formdata, password: e.target.value };
              }}
              name="password"
              required={true}
            />
          </Col>
          <div className="mb-2 mt-3" style={{ textAlign: "right" }}>
            <a href="/request-passcode" style={{textDecoration:"none"}}>Forgot Password?</a>
          </div>
              <p style={{textAlign:"center"}}>v{VERSION}</p>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Col sm={{ span: 10, offset: 2 }}>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={disabled}
            >
              Login
            </button>
          </Col>
        </Form.Group>
      </Form>
    );
  };
  const StudentForm = () => {
    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          const schoolPin = e.currentTarget.elements.namedItem("schoolPin").value;
          const studentPin = e.currentTarget.elements.namedItem("studentPin").value;
          formdata = {...formdata,schoolPin:schoolPin,studentPin:studentPin}
          Login()
        }}
      >
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalSchool">
          <Form.Label column sm={3}>
            Registration ID
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="text"
              placeholder=""
              onChange={(e) => {
                formdata = { ...formdata, schoolPin: e.target.value };
              }}
              name="schoolPin"
              required={true}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalSchool">
          <Form.Label column sm={3}>
            Class PIN
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="text"
              placeholder=""
              onChange={(e) => {
                formdata = { ...formdata, studentPin: e.target.value };
              }}
              name="studentPin"
              required={true}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Col sm={{ span: 10, offset: 2 }}>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={disabled}
            >
              Login
            </button>
          </Col>
        </Form.Group>
      </Form>
    );
  };
  return (
    <>
      <div className="App-container" style={{background:"none"}}>
        <div style={{background:"white",padding:"1px",width:"500px"}}>
        <img src="competition-login-header.png" style={{marginBottom:"20px"}}/>
        <div className="App-page-title">Let's get started!</div>
        <div className="mb-3" >
          <Form.Check
            inline
            label="Teacher"
            onChange={(e) => {
              setLoginType(e.currentTarget.value)
              setMessage("")
            }}
            name="group1"
            type="radio"
            value={loginTypes.Teacher}
            defaultChecked={true}
          />
          <Form.Check
            inline
            label="Student"
            name="group1"
            type="radio"
            onChange={(e) => {
              setLoginType(e.currentTarget.value)
              setMessage("")
            }}
            value={loginTypes.Student}
          />
        </div>
          <div style={{margin:"20px"}}>
          {message? <div className="mb-4" style={{color:"red"}}>{message}</div>:null}
          {loginTypes.Teacher === loginType && <TeacherForm />}
          {loginTypes.Student === loginType && <StudentForm />}
          </div>
        </div>
      </div>
    </>
  );
};

const headers = () => {
    return {
        'Content-Type': 'application/json',
        "X-Access-Token": JSON.parse(localStorage.getItem("user"))?.accessToken,
    }
}
const FetchClient = {
    async get(url) {
        return await fetch(url, {
            headers: headers()
        })
    },
    async delete(url, body) {
        return await fetch(url, {
            method: 'DELETE',
            body: JSON.stringify(body),
            headers: headers()
        })
    },
    async post(url, body) {        
        return await fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: headers()
        })
    }
}
export default FetchClient
export const AnimationMaps = [
  {
    category: "comprehension",
    lottie: "ANIM_real_section6.json",
    audio: "",
  },
  {
    category: "comprehension",
    lottie: "ANIM_real_fluency1.json",
    audio: "EN_real_fluency1.mp3",
  },
  {
    category: "comprehension",
    lottie: "ANIM_real_fluency2.json",
    audio: "EN_real_fluency2.mp3",
  },
  {
    category: "comprehension",
    lottie: "ANIM_real_fluency3.json",
    audio: "EN_real_fluency3.mp3",
  },
  {
    category: "comprehension",
    lottie: "ANIM_real_fluency4.json",
    audio: "EN_real_fluency4.mp3",
  },
  {
    category: "comprehension",
    lottie: "ANIM_real_fluency5.json",
    audio: "EN_real_fluency5.mp3",
  },
  {
    category: "comprehension",
    lottie: "ANIM_real_fluency6.json",
    audio: "",
  },
  {
    category: "comprehension",
    lottie: "ANIM_real_letter6.json",
    audio: "",
  },
  {
    category: "comprehension",
    lottie: "ANIM_real_letter7.json",
    audio: "EN_real_comp1.mp3",
  },
  {
    category: "comprehension",
    lottie: "ANIM_real_comp1.json",
    audio: "EN_real_comp2.mp3",
  },
  {
    category: "comprehension",
    lottie: "ANIM_real_comp2.json",
    audio: "EN_real_comp3.mp3",
  },
  {
    category: "comprehension",
    lottie: "ANIM_real_letter5.json",
    audio: "",
  },
  {
    category: "comprehension",
    lottie: "ANIM_real_letter6.json",
    audio: "",
  },
  {
    category: "comprehension",
    lottie: "",
    audio: "EN_real_comp3.mp3",
  },
  {
    category: "comprehension",
    lottie: "",
    audio: "EN_real_comp4.mp3",
  },
  {
    category: "comprehension",
    lottie: "",
    audio: "EN_real_comp5.mp3",
  },
  {
    category: "comprehension",
    lottie: "",
    audio: "EN_real_comp6.mp3",
  },
  {
    category: "comprehension",
    lottie: "",
    audio: "EN_real_comp7.mp3",
  },
  {
    category: "comprehension",
    lottie: "ANIM_real_comp3.json",
    audio: "SFX_real_progress.mp3",
  },
  {
    category: "comprehension",
    lottie: "ANIM_real_gem6.json",
    audio: "SFX_real_gem.mp3",
  },
  {
    category: "comprehension",
    lottie: "ANIM_real_letter16.json",
    audio: "EN_real_comp7.mp3",
  },
  {
    category: "comprehension",
    lottie: "ANIM_real_letter17.json",
    audio: "",
  },
  {
    category: "comprehension",
    lottie: "ANIM_real_still-book-read.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "ANIM_real_err1.json",
    audio: "EN_real_err1.mp3",
  },
  {
    category: "error",
    lottie: "ANIM_real_err2.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "ANIM_real_err3.json",
    audio: "EN_real_err2.mp3",
  },
  {
    category: "error",
    lottie: "ANIM_real_err4.json",
    audio: "EN_real_err3.mp3",
  },
  {
    category: "error",
    lottie: "ANIM_real_err5.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "ANIM_real_err6.json",
    audio: "EN_real_err2.mp3",
  },
  {
    category: "error",
    lottie: "ANIM_real_err7.json",
    audio: "EN_real_err3.mp3",
  },
  {
    category: "error",
    lottie: "ANIM_real_err8.json",
    audio: "EN_real_err2.mp3",
  },
  {
    category: "error",
    lottie: "ANIM_real_err9.json",
    audio: "EN_real_err3.mp3",
  },
  {
    category: "error",
    lottie: "ANIM_real_err10.json",
    audio: "EN_real_err2.mp3",
  },
  {
    category: "error",
    lottie: "ANIM_real_err-replace1.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "ANIM_real_err-replace2.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "",
    audio: "SFX_real_progress.mp3",
  },
  {
    category: "error",
    lottie: "",
    audio: "SFX_real_gem.mp3",
  },
  {
    category: "error",
    lottie: "upload-sample.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "",
    audio: "EN_real_err3-with-silence.mp3",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_section5.json",
    audio: "",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_phonemic1.json",
    audio: "EN_real_famword1.mp3",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_phonemic2.json",
    audio: "EN_real_famword2.mp3",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_phonemic3.json",
    audio: "EN_real_famword3.mp3",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_letter1-cat.json",
    audio: "",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_letter2-cat.json",
    audio: "EN_real_famword4.mp3",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_letter3-cat.json",
    audio: "EN_real_famword5.mp3",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_letter4-cat.json",
    audio: "EN_real_famword6.mp3",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_letter5-sick.json",
    audio: "",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_letter6-sick.json",
    audio: "",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_letter5.json",
    audio: "",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_letter6.json",
    audio: "",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_letter7-sick.json",
    audio: "EN_real_famword7.mp3",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_letter8-sick.json",
    audio: "EN_real_famword8.mp3",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_letter9-sick.json",
    audio: "EN_real_famword9.mp3",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_letter5-made.json",
    audio: "",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_letter6-made.json",
    audio: "",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_letter7-made.json",
    audio: "EN_real_famword10.mp3",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_letter10-made.json",
    audio: "EN_real_famword11.mp3",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_letter11-made.json",
    audio: "EN_real_famword12.mp3",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_letter12-made.json",
    audio: "EN_real_famword13.mp3",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_letter13.json",
    audio: "",
  },

  {
    category: "familiar",
    lottie: "",
    audio: "EN_real_famword10.mp3",
  },
  {
    category: "familiar",
    lottie: "",
    audio: "EN_real_famword11.mp3",
  },
  {
    category: "familiar",
    lottie: "",
    audio: "EN_real_famword12.mp3",
  },
  {
    category: "familiar",
    lottie: "",
    audio: "EN_real_famword13.mp3",
  },
  {
    category: "familiar",
    lottie: "",
    audio: "EN_real_famword2.mp3",
  },
  {
    category: "familiar",
    lottie: "",
    audio: "EN_real_famword3.mp3",
  },
  {
    category: "familiar",
    lottie: "",
    audio: "EN_real_famword4.mp3",
  },
  {
    category: "familiar",
    lottie: "",
    audio: "EN_real_famword5.mp3",
  },
  {
    category: "familiar",
    lottie: "",
    audio: "EN_real_famword6.mp3",
  },
  {
    category: "familiar",
    lottie: "",
    audio: "EN_real_famword7.mp3",
  },
  {
    category: "familiar",
    lottie: "",
    audio: "EN_real_famword8.mp3",
  },

  {
    category: "familiar",
    lottie: "",
    audio: "EN_real_famword9.mp3",
  },
  {
    category: "familiar",
    lottie: "ANIM_real_still-book-read.json",
    audio: "",
  },
  {
    category: "fluency",
    lottie: "",
    audio: "EN_real_fluency1.mp3",
  },
  {
    category: "fluency",
    lottie: "",
    audio: "EN_real_fluency2.mp3",
  },
  {
    category: "fluency",
    lottie: "",
    audio: "EN_real_fluency3.mp3",
  },
  {
    category: "fluency",
    lottie: "",
    audio: "EN_real_fluency4.mp3",
  },
  {
    category: "fluency",
    lottie: "",
    audio: "EN_real_fluency5.mp3",
  },
  {
    category: "fluency",
    lottie: "ANIM_real_still-book-read.json",
    audio: "",
  },
  {
    category: "intro",
    lottie: "ANIM_real_intro1.json",
    audio: "SFX_real_theme.mp3",
  },
  {
    category: "intro",
    lottie: "ANIM_real_intro2.json",
    audio: "",
  },
  {
    category: "intro",
    lottie: "ANIM_real_intro3.json",
    audio: "EN_real_intro1 .mp3",
  },
  {
    category: "intro",
    lottie: "ANIM_real_intro4.json",
    audio: "EN_real_intro2.mp3",
  },
  {
    category: "intro",
    lottie: "ANIM_real_intro5.json",
    audio: "",
  },
  {
    category: "intro",
    lottie: "ANIM_real_intro6.json",
    audio: "",
  },
  {
    category: "intro",
    lottie: "ANIM_real_intro7.json",
    audio: "EN_real_intro3.mp3",
  },
  {
    category: "intro",
    lottie: "ANIM_real_intro8.json",
    audio: "EN_real_intro4.mp3",
  },
  {
    category: "intro",
    lottie: "ANIM_real_intro9.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "ANIM_real_intro9.json",
    audio: "",
  },
  {
    category: "letter",
    lottie: "ANIM_real_section3.json",
    audio: "",
  },
  {
    category: "letter",
    lottie: "ANIM_real_phonemic1.json",
    audio: "EN_real_letter1.mp3",
  },
  {
    category: "letter",
    lottie: "ANIM_real_phonemic2.json",
    audio: "EN_real_letter2.mp3",
  },
  {
    category: "letter",
    lottie: "ANIM_real_phonemic3.json",
    audio: "EN_real_letter3.mp3",
  },
  {
    category: "letter",
    lottie: "ANIM_real_letter1-T.json",
    audio: "",
  },
  {
    category: "letter",
    lottie: "ANIM_real_letter2-T.json",
    audio: "EN_real_letter4 .mp3",
  },
  {
    category: "letter",
    lottie: "ANIM_real_letter3-T.json",
    audio: "EN_real_letter5.mp3",
  },
  {
    category: "letter",
    lottie: "ANIM_real_letter4-T.json",
    audio: "EN_real_letter6.mp3",
  },
  {
    category: "letter",
    lottie: "ANIM_real_letter5-S.json",
    audio: "",
  },
  {
    category: "letter",
    lottie: "ANIM_real_letter6-S.json",
    audio: "",
  },
  {
    category: "letter",
    lottie: "ANIM_real_letter5-M.json",
    audio: "",
  },
  {
    category: "letter",
    lottie: "ANIM_real_letter6-M.json",
    audio: "",
  },
  {
    category: "letter",
    lottie: "ANIM_real_letter7-M.json",
    audio: "EN_real_letter7.mp3",
  },
  {
    category: "letter",
    lottie: "ANIM_real_letter8-M.json",
    audio: "EN_real_letter8.mp3",
  },
  {
    category: "letter",
    lottie: "ANIM_real_letter9-M.json",
    audio: "EN_real_letter9.mp3",
  },
  {
    category: "letter",
    lottie: "ANIM_real_letter7-S.json",
    audio: "EN_real_letter10.mp3",
  },
  {
    category: "letter",
    lottie: "ANIM_real_letter10-S.json",
    audio: "EN_real_letter11.mp3",
  },
  {
    category: "letter",
    lottie: "ANIM_real_letter11-S.json",
    audio: "EN_real_letter12.mp3",
  },
  {
    category: "letter",
    lottie: "ANIM_real_letter12-S.json",
    audio: "EN_real_letter13.mp3",
  },
  {
    category: "letter",
    lottie: "ANIM_real_letter13.json",
    audio: "",
  },
  {
    category: "letter",
    lottie: "ANIM_real_letter5.json",
    audio: "",
  },
  {
    category: "letter",
    lottie: "ANIM_real_letter6.json",
    audio: "",
  },
  {
    category: "letter",
    lottie: "ANIM_real_still-book-read.json",
    audio: "",
  },
  {
    category: "listening",
    lottie: "ANIM_real_section1.json",
    audio: "",
  },
  {
    category: "listening",
    lottie: "ANIM_real_listen1.json",
    audio: "EN_real_listen1.mp3",
  },
  {
    category: "listening",
    lottie: "ANIM_real_listen2.json",
    audio: "EN_real_listen2.mp3",
  },
  {
    category: "listening",
    lottie: "ANIM_real_listen3.json",
    audio: "EN_real_listen3.mp3",
  },
  {
    category: "listening",
    lottie: "ANIM_real_listen4.json",
    audio: "EN_real_listen4.mp3",
  },
  {
    category: "listening",
    lottie: "ANIM_real_listen5.json",
    audio: "",
  },
  {
    category: "listening",
    lottie: "ANIM_real_listen6.json",
    audio: "EN_real_listen5.mp3",
  },
  {
    category: "listening",
    lottie: "ANIM_real_listen7.json",
    audio: "",
  },
  {
    category: "listening",
    lottie: "ANIM_real_listen8.json",
    audio: "",
  },
  {
    category: "listening",
    lottie: "ANIM_real_listen9.json",
    audio: "EN_real_listen6.mp3",
  },
  {
    category: "listening",
    lottie: "ANIM_real_listen9.json",
    audio: "EN_real_listen7.mp3",
  },
  {
    category: "listening",
    lottie: "ANIM_real_listen9.json",
    audio: "EN_real_listen8.mp3",
  },
  {
    category: "listening",
    lottie: "ANIM_real_still-book-read.json",
    audio: "",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_section4.json",
    audio: "",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_phonemic1.json",
    audio: "EN_real_nonword1.mp3",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_phonemic2.json",
    audio: "EN_real_nonword2.mp3",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_phonemic3.json",
    audio: "EN_real_nonword3.mp3",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter1-ut.json",
    audio: "",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter2-ut.json",
    audio: "EN_real_nonword4.mp3",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter3-ut.json",
    audio: "EN_real_nonword5.mp3",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter4-ut.json",
    audio: "EN_real_nonword6.mp3",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter5-dit.json",
    audio: "",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter6-dit.json",
    audio: "",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter7-dit.json",
    audio: "EN_real_nonword7.mp3",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter8-dit.json",
    audio: "EN_real_nonword8.mp3",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter9-dit.json",
    audio: "EN_real_nonword9.mp3",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter5-mab.json",
    audio: "",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter6-mab.json",
    audio: "",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter7-mab.json",
    audio: "EN_real_nonword10.mp3",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_listen8.json",
    audio: "",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter7-mab.json",
    audio: "EN_real_nonword10.mp3",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter10-mab.json",
    audio: "EN_real_nonword11.mp3",
  },
  
  {
    category: "nonword",
    lottie: "ANIM_real_letter11-mab.json",
    audio: "EN_real_nonword12.mp3",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter12-mab.json",
    audio: "EN_real_nonword13.mp3",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter12.json",
    audio: "",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter1.json",
    audio: "",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter2.json",
    audio: "EN_real_nonword4.mp3",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_phonemic1.json",
    audio: "EN_real_nonword1.mp3",
  },
  {
    category: "nonword",
    lottie: "",
    audio: "EN_real_nonword10.mp3",
  },
  {
    category: "nonword",
    lottie: "",
    audio: "EN_real_nonword11.mp3",
  },
  {
    category: "nonword",
    lottie: "",
    audio: "EN_real_nonword12.mp3",
  },
  {
    category: "nonword",
    lottie: "",
    audio: "EN_real_nonword13.mp3",
  },
  {
    category: "nonword",
    lottie: "",
    audio: "EN_real_nonword2.mp3",
  },
  {
    category: "nonword",
    lottie: "",
    audio: "EN_real_nonword3.mp3",
  },
  {
    category: "nonword",
    lottie: "",
    audio: "EN_real_nonword4.mp3",
  },
  {
    category: "nonword",
    lottie: "",
    audio: "EN_real_nonword5.mp3",
  },
  {
    category: "nonword",
    lottie: "",
    audio: "EN_real_nonword6.mp3",
  },
  {
    category: "nonword",
    lottie: "",
    audio: "EN_real_nonword7.mp3",
  },
  {
    category: "nonword",
    lottie: "",
    audio: "EN_real_nonword8.mp3",
  },
  {
    category: "nonword",
    lottie: "",
    audio: "EN_real_nonword9.mp3",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_letter5.json",
    audio: "",
  },
  {
    category: "nonword",
    lottie: "ANIM_real_still-book-read.json",
    audio: "",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_section2.json",
    audio: "",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic1.json",
    audio: "EN_real_phonemic1.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic2.json",
    audio: "EN_real_phonemic2.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic3.json",
    audio: "EN_real_phonemic3.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic4.json",
    audio: "EN_real_phonemic4.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_intro5.json",
    audio: "",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_intro6.json",
    audio: "",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic5.json",
    audio: "EN_real_phonemic5.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic4.json",
    audio: "EN_real_phonemic6.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic5.json",
    audio: "EN_real_phonemic7.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic6.json",
    audio: "EN_real_phonemic8.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic7.json",
    audio: "EN_real_phonemic9.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic8.json",
    audio: "EN_real_phonemic10.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic4.json",
    audio: "EN_real_phonemic11.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic9.json",
    audio: "EN_real_phonemic12.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic9.json",
    audio: "EN_real_phonemic13.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic9.json",
    audio: "EN_real_phonemic14.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic9.json",
    audio: "EN_real_phonemic15.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic9.json",
    audio: "EN_real_phonemic16.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic9.json",
    audio: "EN_real_phonemic17.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic9.json",
    audio: "EN_real_phonemic18.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic9.json",
    audio: "EN_real_phonemic19.mp3",
  },
  {
    category: "phonemic",
    lottie: "",
    audio: "EN_real_phonemic2.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic9.json",
    audio: "EN_real_phonemic20.mp3",
  },
  {
    category: "phonemic",
    lottie: "",
    audio: "EN_real_phonemic3.mp3",
  },
  {
    category: "phonemic",
    lottie: "",
    audio: "EN_real_phonemic4.mp3",
  },
  
  {
    category: "phonemic",
    lottie: "",
    audio: "EN_real_phonemic7.mp3",
  },
  {
    category: "phonemic",
    lottie: "",
    audio: "EN_real_phonemic8.mp3",
  },
  {
    category: "phonemic",
    lottie: "",
    audio: "EN_real_phonemic9.mp3",
  },
  
  {
    category: "phonemic",
    lottie: "ANIM_real_phonemic9.json",
    audio: "EN_real_phonemic12.mp3",
  },
  {
    category: "phonemic",
    lottie: "ANIM_real_still-book-read.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "ANIM_real_gem1.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "ANIM_real_gem2.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "ANIM_real_gem3.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "ANIM_real_gem4.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "ANIM_real_gem5.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "ANIM_real_gem6.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "ANIM_real_gem1-sparkle.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "ANIM_real_gem2-sparkle.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "ANIM_real_gem3-sparkle.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "ANIM_real_gem4-sparkle.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "ANIM_real_gem5-sparkle.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "ANIM_real_gem6-sparkle.json",
    audio: "",
  },
  {
    category: "error",
    lottie: "ANIM_real_loading-white.json",
    audio: "",
  }
];

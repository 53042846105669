import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Screen.module.css';
import { useNavigate } from 'react-router-dom';
import { IsLoggedIn, SectionUtility } from "../../utils/tools";
import  ANIM_real_intro1 from  "../../pages/intro/lottie/ANIM_real_intro1.json"
import Login from "../../pages/login";
import Lottie from "react-lottie-player";
import NavBar from "../NavBar/NavBar";


const LandingScreen = ({onClick}) => {
  
  const navigation = useNavigate();
  const [startButton, setStartButton] = useState("/buttons/button_start_default.svg")
  useEffect(() => {

  }, []);
  if (!IsLoggedIn()) return (<Login />)
  const IntroAnim = () => {
    return (
      <Lottie
        play={true}
        loop={false}
        animationData={ANIM_real_intro1}
        path=""
        style={{
          width: "50%",
          height: "50%",
          display: "inline-grid",
          placeContent: "center",
        }}
      />
    );
  };
  return (
    <>
      <IntroAnim />
      <div className="row"></div>
      <img
        className="landing-btn"
        onClick={() => {
          setStartButton("/buttons/button_start_pressed.svg");
          onClick()
          
          // setTimeout(() => {
          //   //navigation("/introduction");
          //   onClick()
          // }, 200);
        }}
        style={{
          width: "150px",
          height: "73px",
          flexShrink: 0,
          backgroundSize: "100% 100%",
          position: "relative",
          textAlign: "center",
        }}
        src={startButton}
      />

    </>
  );
}

LandingScreen.propTypes = {};

LandingScreen.defaultProps = {};

export default LandingScreen;

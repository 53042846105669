import React, { useState } from "react";
import NavBar from "../components/NavBar/NavBar";
import { Services } from "../services";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
export const RequestPasscode = () => {
  const [disabled, setDisabled] = useState(false);

  let formdata = { email: "" };
  return (
    <>
      <div className="App-container">
        <div className="App-page-title">Reset Password</div>
        <div
          id="result"
          className="mb-3"
          style={{ visibility: "hidden", color: "green" }}
        >
          Reset link has been sent to your email.
        </div>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            const msg = document.getElementById("result")
            const f = e.currentTarget
            const email = e.currentTarget.elements.item("email").value;
            Services.User.PasswordReset({
              username: email,
            }).then((response) => {
              msg.style.visibility = "visible";
              f.style.visibility = "hidden";
            }).catch((e)=>{
              console.log(e)
            })
          }}
        >
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalSchool"
          >
            <Form.Label column sm={3}>
              Email
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="email"
                placeholder=""
                onChange={(e) => {
                  formdata = { ...formdata, email: e.target.value };
                }}
                name="email"
                required={true}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Col sm={{ span: 10, offset: 2 }}>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={disabled}
              >
                Send Reset Link
              </button>
            </Col>
          </Form.Group>
        </Form>
      </div>
    </>
  );
};

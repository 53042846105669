import React from 'react';
import styles from './GemTray.module.css';
import ActiveButton from "./gem-tray-0.png"
import InActiveButton from "./gem-tray-0.png"

import ActiveButton1 from "./gem-tray-1.png"
import ActiveButton2 from "./gem-tray-2.png"
import ActiveButton3 from "./gem-tray-3.png"
import ActiveButton4 from "./gem-tray-4.png"
import ActiveButton5 from "./gem-tray-5.png"
import ActiveButton6 from "./gem-tray-6.png"

const GemTray = (props) => {
  let Button = ActiveButton
  switch(props.Number){
    case 0:
      Button = ActiveButton
      break;
    case 1:
      Button = ActiveButton1
      break;
    case 2:
      Button = ActiveButton2
      break;
    case 3:
      Button = ActiveButton3
      break;
    case 4:
      Button = ActiveButton4
      break;
    case 5:
      Button = ActiveButton5
      break;
    case 6:
      Button = ActiveButton6
      break;
    case 7:
      Button = ActiveButton1
      break;
  }
 return (
    <span
      className={styles.GemTray}
      onClick={props.onClick}
      style={{ background: 'url(\'' + (props?.Disable && props?.Disable===true?InActiveButton:Button) + '\') rgb(111 60 254) center/cover' }}
      >
    </span>
  );
}


GemTray.propTypes = {};

GemTray.defaultProps = {};

export default GemTray;

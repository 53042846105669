import { createAction } from "redux-actions";

// create constant
export const GetExamRdxConst = "Exam/GetExam";

// create action
export const GetExamAction = createAction(
  GetExamRdxConst,
  (payload) => payload
);

// Create reducer
export const GetExamReducer = (state, action) => {
  return state;
};

import React from 'react';
import styles from './CircleButton.module.css';
import ActiveButton from "./button.png"
import InActiveButton from "./button-disabled.png"
const CircleButton = (props) => {
 return (
    <span
      className={styles.CircleButton}
      onClick={props.onClick}
      style={{ background: 'url(\'' + (props?.Disable && props?.Disable===true?"/buttons/button_check_disabled.svg":"/buttons/button_check_default.svg") + '\') rgb(111 60 254) center/cover' }}>
    </span>
  );
}


CircleButton.propTypes = {};

CircleButton.defaultProps = {};

export default CircleButton;

import FetchClient from "../api/client";
import AnimationService from "./animation";
import ExamService from "./exam";
import UserService from "./user";
import SchoolService from "./school";

export const Services = {
  User: new UserService(FetchClient),
  Exam: new ExamService(FetchClient),
  Animation: new AnimationService(FetchClient),
  School: new SchoolService(FetchClient),
};

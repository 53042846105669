import { URLS } from "../constants/config";
import { AnimationMaps } from "../constants/animation_maps";
import { Services } from ".";
class AnimationService {
  static categories = {
    Comprehension: "comprehension",
    Error: "error",
    Familiar: "familiar",
    Fluency: "fluency",
    Intro: "intro",
    Letter: "letter",
    Listening: "listening",
    Nonword: "nonword",
    Phonemic: "phonemic",
  };
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  Categories = {
    Comprehension: "comprehension",
    Error: "error",
    Familiar: "familiar",
    Fluency: "fluency",
    Intro: "intro",
    Letter: "letter",
    Listening: "listening",
    Nonword: "nonword",
    Phonemic: "phonemic",
  };

  getSectionData(category) {
    return AnimationMaps.filter((item) => {
      return item?.category === category;
    }).map((item) => {
      return item;
    });
  }

  getErrorAssets() {
    return this.getSectionAssets(this.Categories.Error);
  }

  //loading, ended stated
  getGemValue(sectionId, sectionState = "loading") {
    let result = 0;
    switch (sectionId) {
      case 1:
        result = 0;
        break;
      case 2:
        result = 2;

        break;
      case 3:
        result = 3;

        break;
      case 4:
        result = 4;

        break;
      case 5:
        result = 5;
        break;
      case 6:
        result = 5;

        break;
      case 7:
        result = 1;

        break;
      default:
        result = 0;
        break;
    }
    if(sectionState === "ended" && sectionId!==5)
      result++
    return result;
  }

  getLottieAnimation(data, lottieName) {
    return data.find((item) => {
      return item?.lottie === lottieName;
    })?.lottie_json;
  }

  getPreloadedAudio(data, audioName) {
    return data.find((item) => {
      return item?.audio === audioName;
    })?.preloads;
  }

  getSectionAssets = (category) => {
    var languageSwitch = Services?.User?.getLocalStorageUser()?.language?.toLocaleLowerCase()
    var prefix = ""
    if(languageSwitch == "ea"){
      prefix = "ZAM-"
    }

    return new Promise(async (resolve, reject) => {
      const data = this.getSectionData(category).concat(
        this.getSectionData(this.Categories.Error)
      );
      // console.log(URLS.baseuri, 'URLS.baseuri')
      // const dir = `${URLS.baseuri}/animation-assets`;
      let allData = [];
      await Promise.all(
        data.map(async (item) => {
          
          const nw_record = { ...item };
          if (item?.lottie.length > 0) {
            // let lottieName = `./animation-assets/lottie/${item?.lottie}`
            // nw_record["lottie_json"] = 
            // require(`../animation-assets/lottie/${item?.lottie}`)
            // console.log(nw_record["lottie_json"])
            // await this.httpClient
            //   .get(dir + "/lottie/" + item?.lottie)
            //   .then((response) => {
            //     if (response.ok) {
            //       return response.json().then((data) => {
            //         return data;
            //       });
            //     }
            //   });
          //     await fetch(dir + "/lottie/" + item?.lottie)
          //     .then((response) => {
          //       if (response.ok) {
          //         return response.json().then((data) => {
          //           return data;
          //         });
          //       }
          //     });
          }

          if (item?.audio.length > 0) {
            // const _audio = new Audio(dir + "/"+languageSwitch+"/"+prefix+item?.audio);
            // const _audio = new Audio(`${dir}/${languageSwitch}/${prefix}${item?.audio}`);
            const _audio = new Audio(`https://d1o7jiud52c9lk.cloudfront.net/audio/${languageSwitch}/${prefix}${item?.audio}`)
            _audio.load();
            nw_record["preloads"] = _audio;
          }
          allData.push(
            new Promise((resolve, reject) => {
              resolve(nw_record);
            })
          );
        })
      )
      .catch((e) =>{
        console.log(e)
      })

      await Promise.all(allData).then((data) => {
        resolve(data);
      });
    });
  };

  getSectionAudios = (category) => {
    console.log("before promise")
    return new Promise(async (resolve, reject) => {
      const data = this.getSectionData(category).concat(
        this.getSectionData(this.Categories.Error)
      );
      const dir = "/animation-assets";
      let allData = [];
      await Promise.all(
        data.map(async (item) => {
          // console.log(item)
          const nw_record = { ...item };
          // if (item?.lottie.length > 0) {
          //   nw_record["lottie_json"] = await this.httpClient
          //     .get(dir + "/lottie/" + item?.lottie)
          //     .then((response) => {
          //       if (response.ok) {
          //         return response.json().then((data) => {
          //           return data;
          //         });
          //       }
          //     });
          // }

          if (item?.audio.length > 0) {
            const _audio = new Audio(dir + "/en/" + item?.audio);
            _audio.load();
            nw_record["preloads"] = _audio;
          }
          allData.push(
            new Promise((resolve, reject) => {
              resolve(nw_record);
            })
          );
        })
      )
      .catch((e) =>{
        console.log(e)
      })

      await Promise.all(allData).then((data) => {
        resolve(data);
      });
    });
  };
}

export default AnimationService;

import { URLS } from "../constants/config";
import { AudioMarker } from "../utils/tools";
import { v4 as uuidv4 } from 'uuid';
async function UploadSectionData(state) {
  const data = new FormData();
  state.recordings
    .filter((x) => {
      return x.blob?.size != null;
    })
    .forEach((r, i) => {
      console.log(r?.label);
      console.log(URL.createObjectURL(r.blob));
      data.append(`audio`, r.blob, `file-${i}` + uuidv4() + `.mp3`);
    });
  const markers = AudioMarker(state);
  data.append(`audio_markers`, JSON.stringify(markers));
  data.append('page_type', state.pagetype.id)
  let u = JSON.parse(localStorage.getItem('user'))
  data.append('examUuid', u?.exam)
  console.log(JSON.stringify(markers))

  let token = JSON.parse(localStorage.getItem("user")).accessToken
  return await fetch(URLS.INTERNAL.MERGE_AUDIO, {
    headers: {
      "x-access-token": token
    },
    method: "POST",
    body: data,
  })
};
export default UploadSectionData
import { createAction } from "redux-actions";

export const DecrementCounterRdxConst = "Counter/DecrementCounter";

// create action
export const DecrementCounterAction = createAction(DecrementCounterRdxConst);

// Create reducer
export const DecrementCounterReducer = (state, action) => {
  return state - 1;
};

import React, { useState } from "react";
import NavBar from "../components/NavBar/NavBar";
import { Services } from "../services";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
export const NoSoundPage = () => {

  return (
    <>
      <div className="App-container">
        <div className="App-page-title">React Out to your Teacher</div>        
      </div>
    </>
  );
};

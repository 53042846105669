import "./App.css";
import React, {  } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";


import Section from "./pages/section-intro";

import { Registration } from "./pages/registration";
import { ListRecordings } from "./pages/listrecordings";
import { Moderate } from "./pages/moderate";
import { ChangePassword } from "./pages/change-password";
import { GlobalIntro } from "./pages/intro";
import Scores from "./pages/scores";
import { Dataset } from "./pages/dataset.js";
import { RequestPasscode } from "./pages/request-passcode";
import { ResetPasswordForm } from "./pages/reset-password-form";
import { CompetitionLogin } from "./pages/competition-login.js";
import { NoSoundPage } from "./pages/nosoundpage.js";
import LoginTool from "./pages/login.js";

export function App() {  
  return (
    <BrowserRouter>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="App">
              <Routes>
                <Route path="/" element={<GlobalIntro />} />
                <Route path="/logintool" element={<LoginTool />} />
                <Route path="/no-sound" element={<NoSoundPage />} />
                <Route path="/introduction" element={<GlobalIntro />} />
                <Route path="/section/:id" element={<Section />} />
                <Route path="/register" element={<Registration />} />
                <Route path="/list-recordings" element={<ListRecordings />} />
                <Route path="/moderate" element={<Moderate />} />
                <Route path="/dataset" element={<Dataset />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/request-passcode" element={<RequestPasscode />} />
                <Route path="/reset-password-form/:reset_token/:email" element={<ResetPasswordForm />} />
                <Route path="/scores/:id" element={<Scores />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;

import { URLS } from "../constants/config";

class UserService {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  getLocalStorageUser() {
    let item = localStorage.getItem("user");
    return item ? JSON.parse(item) : null;
  }
  //created to give redirect url to squid login
  isSquidStudent() {
    let u = this.getLocalStorageUser();
    return u && u.roles.includes("student") && u?.accountType==="squid" ? true : false;
  }
  isModerator() {
    let u = this.getLocalStorageUser();
    return u && u.roles.includes("moderator") ? true : false;
  }
  isModeratorOrTeacher() {
    return this.isModerator() || this.isTeacher()?true:false
  }

  isTeacher() {
    let u = this.getLocalStorageUser();
    return u && u.roles.includes("teacher") ? true : false;
  }

  getLanguage(){
    var languageSwitch = this.getLocalStorageUser()?.language?.toLocaleLowerCase()
    if(languageSwitch == undefined){
      languageSwitch = "en"
    }
    return languageSwitch
  }

  async WholeClassPINReset(body){
    try {
      let url = URLS.INTERNAL.PIN_RESET;
      const response = await this.httpClient.post(url,body);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
  async PasswordReset(body){
    try {
      let url = URLS.INTERNAL.PASSWORD_RESET;
      const response = await this.httpClient.post(url,body);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
  async CheckPasswordResetToken(token,email){
    try {
      let url = URLS.INTERNAL.CHECK_PASSWORD_RESET_TOKEN(token,email)
      const response = await this.httpClient.get(url);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }

  getLocalStorageDetails() {
    let item = localStorage.getItem("user");
    let user = item ? JSON.parse(item) : null;
    return {
      name: user?.name,
      username:user?.username,
      schoolUuid:user?.schoolUuid,
      roles:user?.roles,
      userUuid:user?.userUuid,
      schoolName:user?.schoolName,
      email:user?.email
    };
  }

  async showStudentInfo(id) {
    try {
      let url = URLS.INTERNAL.STUDENT_INFO(id);
      const response = await this.httpClient.get(url);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }

  async showAssessmentInfo(id) {
    try {
      let url = URLS.INTERNAL.ASSESSMENT_INFO(id);
      const response = await this.httpClient.get(url);
      return response.json();
    } catch (error) {
      console.error(error);
      return { payload: null }
    }
  }

  async BulkRegister(body) {
    try {
      let url = URLS.INTERNAL.BULK_REGISTER;
      const response = await this.httpClient.post(url,body);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
  async CompetitionTeacherLogin(body) {
    try {
      let url = URLS.INTERNAL.COMPETITION_TEACHER_LOGIN;
      const response = await this.httpClient.post(url,body);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
  async CompetitionStudentLogin(body) {
    try {
      let url = URLS.INTERNAL.COMPETITION_STUDENT_LOGIN;
      const response = await this.httpClient.post(url,body);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
  async ChangePassword(body) {
    try {
      let url = URLS.INTERNAL.CHANGE_PASSWORD;
      const response = await this.httpClient.post(url,body);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
  async UpdateNewResettedPassword(body) {
    try {
      let url = URLS.INTERNAL.POST_NEW_RESETTED_PASSWORD;
      const response = await this.httpClient.post(url,body);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}
export default UserService;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Summary.module.css';
import { SectionLists } from '../../constants/config';
import { URLS } from '../../constants/config';
function handleS3Key(key) {
  document.getElementById("audio-button-"+key).disabled = true
  fetch(URLS.INTERNAL.DOWNLOAD_S3_FILE, {
    headers: {
      "Content-Type": "application/json",
      responseType: 'blob'
    },
    method: "POST",
    //"merged-1700746643369.wav"
    body: JSON.stringify({
      "file_name": key
    }),
  }).then((response) => {
    if (response.ok) {
    response.blob().then(async (b)=>{
      let e = document.getElementById("audio-" + key);
      document.getElementById("audio-button-" + key).style.display = "none";
      const a = document.createElement("audio");
      

      a.style.height = "1.5em"
      a.style.width ="10em" 
      a.style.border = "1px solid white"
      a.style.borderRadius = "50px"
      const blob = new Blob([await b.arrayBuffer()], { type: 'audio/mp3;codecs=mp4a'})
      a.src = URL.createObjectURL(blob);
      a.controls = true;
      e.append(a);
    })
    }
  });
}

const ActionAudio = (props) =>{
  const { s3key } = props
  
  if(!s3key)
  return""

  return(
    <>
    <div id={"audio-" + s3key}></div>
    <div>
      <button
        style={{ marginBottom: "5px" }}
        className="btn btn-outline-primary btn-sm"
        data={s3key}
        id={"audio-button-" + s3key}
        onClick={(e) => {
          handleS3Key(s3key, e);
        }}
      >
        Get Audio
      </button>

     
    </div>
  </>
  )
}



const ScoreSummary = ({summary}) =>{

  const TimeStampButton = ({marker})=>{
    if(!marker)
    return ""
    return <button
    data-bs-toggle="modal"
    data-bs-target="#exampleModal"
    data={marker}
    className="btn btn-outline-primary btn-sm"
    onClick={() => {
      handleTimeStamp(marker);
    }}
  >
    View
  </button>
  }

  const [timestamp,setTimeStamp] = useState()
  const handleTimeStamp = (e) =>{
    setTimeStamp(e)
  }

  const findTotalScore = (summary) => {
    let totalScore = 0;
    if (
      summary instanceof Array &&
      summary.length === 5 &&
      summary[4].length === summary[3].length &&
      summary[3].length === 8
    ) {
      for (let i = 0; i <= 8; i++) {
        if (i > 0) {
          totalScore += summary[3][i] ? parseFloat(summary[3][i]) : 0;
          totalScore += summary[4][i]
            ? parseFloat(new String(summary[4][i]).replace("%", ""))
            : 0;
        }
      }
    }
    return totalScore;
  };

  return  (

  <div className={styles.wrapper}>
    <h3 className={styles.heading}>Summary (Total Score: {findTotalScore(summary)})</h3>
    <table className="table table-bordered border-primary table-sm">
      {summary.length>0 &&
      <thead style={{ verticalAlign: "middle" }}>
        <tr key="tr-210">                          
                <th key="th0">{summary[0][0]}</th>
                <th key="th1">{summary[0][1]}</th>
                <th key="th2">{summary[0][2]}</th>
                <th key="th3">{summary[0][3]}</th>
                <th key="th4">{summary[0][4]}</th>
                <th key="th5">{summary[0][5]}</th>
                <th key="th6">{summary[0][6]}</th>
                <th key="th7">{summary[0][7]}</th>
        </tr>
      </thead>
      }
      <tbody style={{  fontWeight: "700" }}>
        {summary.length>0 &&
          summary.filter((item,index)=>{
            return index>0
          }).map((item,index)=>{        
            
            if(index===0)    {
              return <tr key={"tr-"+index+3}>
              <td style={{fontWeight:'bold'}}>{item[0]}</td>
              <td><ActionAudio s3key={item[1]} marker={item[1]} /></td>
              <td><ActionAudio s3key={item[2]} marker={item[1]} /></td>
              <td><ActionAudio s3key={item[3]} marker={item[1]} /></td>
              <td><ActionAudio s3key={item[4]} marker={item[1]} /></td>
              <td><ActionAudio s3key={item[5]} marker={item[1]} /></td>
              <td><ActionAudio s3key={item[6]} marker={item[1]} /></td>
              <td><ActionAudio s3key={item[7]} marker={item[1]} /></td>
              </tr>
            }
            if(index===1)    {
              return <tr key={"tr-"+index+3}>
              <td style={{fontWeight:'bold'}}>{item[0]}</td>
              <td><TimeStampButton marker={item[1]} /></td>
              <td><TimeStampButton marker={item[2]} /></td>
              <td><TimeStampButton marker={item[3]} /></td>
              <td><TimeStampButton marker={item[4]} /></td>
              <td><TimeStampButton marker={item[5]} /></td>
              <td><TimeStampButton marker={item[6]} /></td>
              <td><TimeStampButton marker={item[7]} /></td>
              </tr>
            }
            return <tr key={"tr-"+index+3}>
            <td style={{fontWeight:'bold'}}>{item[0]}</td>
            <td>{item[1]}</td>
            <td>{item[2]}</td>
            <td>{item[3]}</td>
            <td>{item[4]}</td>
            <td>{item[5]}</td>
            <td>{item[6]}</td>
            <td>{item[7]}</td>
            </tr>
          })
        }

      </tbody>
      </table>
      
      <div className="modal fade" id="exampleModal"  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Audio Timestamp</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body" style={{textAlign:"left"}}>
        {timestamp}
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
      </div>
)};

ScoreSummary.propTypes = {};

ScoreSummary.defaultProps = {};

export default ScoreSummary;

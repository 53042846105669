import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { URLS } from "../constants/config";
import useFetch from "../hooks/useFetch";

export const Registration =  ()=> {
  const [schools] = useFetch(URLS.INTERNAL.SCHOOLS_LISTING)
  const navigate = useNavigate()
  let user = JSON.parse(localStorage.getItem("user"))
  if(user)
    navigate("/section/x")

  const [inputs, setInputs] = useState({gender:'male'});
  const [registered, setRegistered] = useState(false);

  const handleChange = (event) => {
    const id = event.target.id;
    const value = event.target.value;
    setInputs(values => ({...values, [id]: value}))
  }

  const Schools = () => {
    if(!schools)
    return ""
    return (
      <>
        <select id="schoolUuid" className="form-control" onChange={handleChange} value={inputs?.schoolUuid?inputs?.schoolUuid:""} required={true} >
          <option value={""}>Select School</option>
          {schools.map(({ schoolUuid, name }, index) => (
            <option key={schoolUuid} value={schoolUuid}>
              {name}
            </option>
          ))}
        </select>
      </>
    );
  };
  
  const SuccessfulRegistration  = () =>{
    return (<><p className="text-white mt-5">Registration is done. Goto <Link to={"/"} >Login</Link> for a test.</p></>)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {...inputs}
    fetch(URLS.INTERNAL.REGISTER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    .then((response) => {
      if(response.ok){
        setRegistered(true)
      }
    },(error)=>{
      console.log(error)
    });
  }

  if (registered) return <SuccessfulRegistration />;

  return (
    <>
      <div
        style={{
          width: "500px",
          textAlign: "center !important",
          display: "inline-grid",
        }}
      >
        <div className="login-form">
          <div className="row">
            <img
              alt=""
              src="sign-in-image.png"
              style={{ height: "200px", display: "block" }}
            />
          </div>
          <form onSubmit={handleSubmit}>
            <br/>
          <h3>Registration (V2)</h3>          
            <div className="mt-5 mb-3" style={{ textAlign: "left" }}>            
              <label htmlFor="username" className="form-label">
                UserName
              </label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="username"
                required={true}
                style={{ height: "fit-content" }}
                value={inputs.username || ""}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3" style={{ textAlign: "left" }}>
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                required={true}
                style={{ height: "fit-content" }}
                value={inputs.password || ""}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
            <div className="mb-3" style={{ textAlign: "left" }}>
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                required={true}
                value={inputs.name || ""}
                onChange={handleChange}
                style={{ height: "fit-content" }}
              />
            </div>
            <div className="mb-3" style={{ textAlign: "left" }}>
              <label htmlFor="school" className="form-label">
                School
              </label>
              <Schools />
            </div>
            <div className="mb-3" style={{ textAlign: "left" }}>
              <label htmlFor="class" className="form-label">
                Class
              </label>
              <input
                type="text"
                className="form-control"
                id="class"
                required={true}
                value={inputs.class || ""}
                onChange={handleChange}
                style={{ height: "fit-content" }}
              />
            </div>
            <div className="mb-3" style={{ textAlign: "left" }}>
              <label htmlFor="email" className="form-label">
                Ethnicity
              </label>
              <input
                type="text"
                className="form-control"
                id="ethnicity"
                required={true}
                value={inputs.ethnicity || ""}
                onChange={handleChange}
                style={{ height: "fit-content" }}
              />
            </div>
            <div className="mb-3" style={{ textAlign: "left" }}>
              <label htmlFor="email" className="form-label">
                Age
              </label>
              <input
                type="text"
                className="form-control"
                id="age"
                required={true}
                value={inputs.age || ""}
                onChange={handleChange}
                style={{ height: "fit-content" }}
              />
            </div>
            <div className="mb-3" style={{ textAlign: "left" }}>
              <label style={{ display: "block" }}>Gender</label>
              <div
                className="form-check"
                style={{ display: "inline-block", marginRight: "15px" }}
              >
                <input
                  className="form-check-input"
                  name="gender"
                  type="radio"
                  value="male"
                  id="gender"
                  defaultChecked
                  style={{ display: "inline-block" }}
                  onChange={handleChange}
                />
                <label
                  className="form-check-label"
                  style={{ display: "inline-block" }}
                >
                  Male
                </label>
              </div>
              <div className="form-check" style={{ display: "inline-block" }}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"                  
                  value="female"
                  id="gender"
                  style={{ display: "inline-block" }}
                  onChange={handleChange}
                />
                <label
                  className="form-check-label"
                  style={{ display: "inline-block" }}
                >
                  Female
                </label>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                background:
                  "linear-gradient(23deg, rgba(64, 8, 183, 0.20) 40.27%, rgba(208, 143, 44, 0.20) 410.01%), #43099E",
              }}
            >
              Register
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default Registration
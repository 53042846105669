import React, { forwardRef,useImperativeHandle, useState } from "react";
import styles from "./Frame.module.css";
import CircleButton from "../ui/CircleButton";
import GemTray from "../ui/GemTray";

const Frame = forwardRef((props, ref) => {
  
  const { content,handler } = props;  
  const ActiveButton = <CircleButton onClick={handler} />
  const DisabledButton = <CircleButton Disable={true} />
  const [Button, setButton] = useState(handler?ActiveButton:DisabledButton);
  const EnableButton = ()=>{    
    setButton(ActiveButton)
  }
  const DisableButton = () => {
    setButton(DisabledButton);
  };
  useImperativeHandle(ref,()=>{
    return{
      EnableButton:EnableButton,
      DisableButton:DisableButton,
    }
  })
  const OuterFrame = () => {
    return (
      <div
        className={styles.BreakpointDesktop}
        style={{
          width: 576,
          height: 484,
          position: "relative",
          boxShadow: "0px 3px 0px 3px #B476FF inset",
          borderRadius: 24,
          overflow: "hidden",
          border: "3px #4D09BD solid",
          margin: "auto",
          background:
            "linear-gradient(180deg, #863CFE 0%, #6F3CFE 76%, #6F3CFE 100%), linear-gradient(180deg, rgba(122.08, 60, 254, 0.50) 0%, rgba(111, 60, 254, 0.50) 43%, rgba(111, 60, 254, 0.50) 100%)",
        }}
      >
        <div
          className="ContentScreenStyling"
          style={{
            width: 534,
            height: 388,
            left: 22,
            top: 22,
            position: "absolute",
            boxShadow: "0px 3px 0px #906AFD",
            borderRadius: 16,
            border: "3px #7926FF solid",
            background:
              "linear-gradient(0deg, #6200EA 0%, #6200EA 100%), linear-gradient(199deg, rgba(255, 183.60, 0, 0.20) 0%, rgba(255, 198.90, 0, 0) 90%)",
          }}
        />
        <div
          className="ContentScreen"
          style={{
            width: 534,
            height: 388,
            left: 22,
            top: 22,
            position: "absolute",
            borderRadius: 16,
            overflow: "hidden",
          }}
        >
          {content}
        </div>
          <GemTray Disable={false} Number={props.GemValue}/>
          {Button}
      </div>
    );
  };
  return (
    <div className={styles.Frame}>
      <OuterFrame />
    </div>
  );
});

Frame.propTypes = {};

Frame.defaultProps = {};

export default Frame;
